// @ngInject
common_module.constant('AppStates', {

});
common_module.config(["AppStates", function (AppStates) {

    AppStates.ng_in_react = 'ng_in_react';
    AppStates.ng_in_react_modal_opener = AppStates.ng_in_react + '.modal_opener';
    AppStates.ng_in_react_component_renderer = AppStates.ng_in_react + '.component_renderer';

    AppStates.root = 'root';
    AppStates.root_core = AppStates.root + '.core';

    AppStates.root_core_automations_apply = AppStates.root_core + '.automationsApply';
    AppStates.root_core_oneFlow = AppStates.root_core + '.oneFlow';
    AppStates.root_core_oneFlowWithAction = AppStates.root_core_oneFlow + '.action';
    AppStates.root_core_oneFlowPreview = AppStates.root_core_oneFlowWithAction + '.oneFlowPreview';
    AppStates.root_core_oneFlowWizardPreview = AppStates.root_core_oneFlow + '.oneFlowWizardPreview';
    AppStates.root_core_oneFlowPreviewWithPageName = AppStates.root_core_oneFlowPreview + '.pageName';
    AppStates.root_core_oneFlowTemplateGalleryPreview = AppStates.root_core  + '.oneFlowTemplateGalleryPreview';
    AppStates.root_core_onboardingGuidance = AppStates.root_core + '.onboardingGuidance';
    AppStates.root_core_onboardingMobileQuiz = AppStates.root_core + '.onboardingMobileQuiz';
    AppStates.root_core_onboardingQuiz = AppStates.root_core + '.onboardingQuiz';
    AppStates.root_core_oneComposer = AppStates.root_core + '.oneComposer';
    AppStates.root_core_native = AppStates.root_core + '.native';
    AppStates.root_core_import_template = AppStates.root_core + '.importTemplate';
    AppStates.root_core_import_template_redeem_error = AppStates.root_core + '.redeemError';

    AppStates.root_core_navigation = AppStates.root_core + '.navigation';
    AppStates.root_core_navigation_dashboard = AppStates.root_core_navigation + '.dashboard';
    AppStates.root_core_navigation_pipeline = AppStates.root_core_navigation + '.pipeline';
    AppStates.root_core_navigation_home = AppStates.root_core_navigation + '.home';

    AppStates.root_core_navigation_calendarAbstract = AppStates.root_core_navigation + '.calendarAbstract';
    AppStates.root_core_navigation_calendar = AppStates.root_core_navigation_calendarAbstract + '.calendar';
    AppStates.root_core_navigation_scheduling = AppStates.root_core_navigation_calendarAbstract + '.scheduling';
    AppStates.root_core_sessionConfig = AppStates.root_core + '.sessionConfig';

    AppStates.root_core_navigation_calendarNewAbstract = AppStates.root_core_navigation + '.calendarNewAbstract';
    AppStates.root_core_navigation_calendar_new = AppStates.root_core_navigation_calendarNewAbstract + '.calendarnew';
    AppStates.root_core_navigation_scheduling_new = AppStates.root_core_navigation_calendarNewAbstract + '.schedulingnew';

    AppStates.root_core_navigation_batch_email = AppStates.root_core_navigation + '.batchEmails';

    AppStates.root_core_navigation_automations = AppStates.root_core_navigation + '.automations';
    AppStates.root_core_navigation_automation_setup = AppStates.root_core_navigation + '.automationSetup';
    AppStates.root_core_navigation_files = AppStates.root_core_navigation + '.workspace_files';
    AppStates.root_core_navigation_client = AppStates.root_core_navigation + '.client_navigation';
    AppStates.root_core_navigation_contacts = AppStates.root_core_navigation + '.contacts';
    AppStates.root_core_navigation_contact_view = AppStates.root_core_navigation_contacts + '.contact_view';
    AppStates.root_core_navigation_contact_view_tab = AppStates.root_core_navigation_contact_view + '.tab';
    AppStates.root_core_navigation_organizations = AppStates.root_core_navigation + '.organization';
    AppStates.root_core_navigation_organization_view = AppStates.root_core_navigation_organizations + '.organization_view';
    AppStates.root_core_navigation_tools_mobile = AppStates.root_core_navigation + '.tools';
    AppStates.root_core_navigation_business_tools = AppStates.root_core_navigation + '.business_tools';
    AppStates.root_core_navigation_dashboard_workspace_cards = AppStates.root_core_navigation_dashboard + '.workspaceCards';
    AppStates.root_core_navigation_activity_notifications = AppStates.root_core_navigation + '.activity_notifications';
    AppStates.root_core_navigation_invite = AppStates.root_core_navigation + '.invite';
    AppStates.root_core_navigation_invite_referrals = AppStates.root_core_navigation_invite + '.referrals';
    AppStates.root_core_navigation_invite_track = AppStates.root_core_navigation_invite + '.track';
    AppStates.root_core_navigation_invite_resources = AppStates.root_core_navigation_invite + '.resources';
    AppStates.root_core_navigation_invite_perks = AppStates.root_core_navigation_invite + '.perks';
    AppStates.root_core_navigation_event_wrapper = AppStates.root_core_navigation + '.event_wrapper';
    AppStates.root_core_navigation_event = AppStates.root_core_navigation_event_wrapper + '.event';
    AppStates.root_core_navigation_emptyEvent = AppStates.root_core_navigation + '.emptyEvent';
    AppStates.root_core_navigation_event_overview = AppStates.root_core_navigation_event + '.overview';
    AppStates.root_core_navigation_event_workspace = AppStates.root_core_navigation_event + '.workspace';
    AppStates.root_core_navigation_event_workspace_files = AppStates.root_core_navigation_event_workspace + '.workspace_files';
    AppStates.root_core_navigation_event_workspace_payments = AppStates.root_core_navigation_event_workspace + '.payments';
    AppStates.root_core_navigation_event_workspace_notes = AppStates.root_core_navigation_event_workspace + '.notes';
    AppStates.root_core_navigation_event_workspace_details = AppStates.root_core_navigation_event_workspace + '.details';
    AppStates.root_core_navigation_event_workspace_feed = AppStates.root_core_navigation_event_workspace + '.feed';
    AppStates.root_core_navigation_tasks = AppStates.root_core_navigation + '.tasks';
    AppStates.root_core_navigation_time_tracker = AppStates.root_core_navigation + '.time_tracker';
    AppStates.root_core_navigation_facebook_leads = AppStates.root_core_navigation + '.facebook_leads';

    AppStates.root_core_navigation_reports = AppStates.root_core_navigation + '.reports';
    AppStates.root_core_navigation_reports_analytics = AppStates.root_core_navigation_reports + '.analytics';
    AppStates.root_core_navigation_reports_payments = AppStates.root_core_navigation_reports + '.payments';
    AppStates.root_core_navigation_profit_loss = AppStates.root_core_navigation_reports + '.profit_loss';
    AppStates.root_core_navigation_reports_fees = AppStates.root_core_navigation_reports + '.fees';
    AppStates.root_core_navigation_reports_referrals = AppStates.root_core_navigation_reports + '.referrals';
    
    AppStates.root_core_navigation_bookkeeping = AppStates.root_core_navigation + '.bookkeeping';
    AppStates.root_core_navigation_bookkeeping_reports = AppStates.root_core_navigation_bookkeeping + '.reports';
    AppStates.root_core_navigation_bookkeeping_reports_analytics = AppStates.root_core_navigation_bookkeeping_reports + '.analytics';
    AppStates.root_core_navigation_bookkeeping_reports_profit_loss = AppStates.root_core_navigation_bookkeeping_reports + '.profit_loss';
    AppStates.root_core_navigation_bookkeeping_reports_payments = AppStates.root_core_navigation_bookkeeping_reports + '.payments';
    AppStates.root_core_navigation_bookkeeping_expenses = AppStates.root_core_navigation_bookkeeping + '.expenses';
    
    AppStates.root_core_navigation_expenses = AppStates.root_core_navigation + '.expenses';
    AppStates.root_core_navigation_finance = AppStates.root_core_navigation + '.finance';
    AppStates.root_core_navigation_finance_overview = AppStates.root_core_navigation + '.overview';
    AppStates.root_core_navigation_invoices = AppStates.root_core_navigation + '.invoices';
    AppStates.root_core_navigation_files_hub = AppStates.root_core_navigation + '.files_hub';
    AppStates.root_core_navigation_capital = AppStates.root_core_navigation + '.capital';

    AppStates.root_core_navigation_gallery_dashboard = AppStates.root_core_navigation + '.gallery_dashboard';
    AppStates.root_core_navigation_lead_capture = AppStates.root_core_navigation + '.lead_capture';
    AppStates.root_core_navigation_settings = AppStates.root_core_navigation + '.settings';
    AppStates.root_core_navigation_bookkeeping_settings = AppStates.root_core_navigation_bookkeeping + '.settings';
    AppStates.root_core_navigation_create_new = AppStates.root_core_navigation + '.createNew';
    AppStates.root_core_navigation_settings_account = AppStates.root_core_navigation_settings + '.account';
    AppStates.root_core_navigation_settings_companySettings = AppStates.root_core_navigation_settings + '.companySettings';
    AppStates.root_core_navigation_bookkeeping_settings_company = AppStates.root_core_navigation_bookkeeping_settings + '.company';
    // AppStates.root_core_navigation_settings_companySettings_overview = AppStates.root_core_navigation_settings_companySettings + '.overview';
    AppStates.root_core_navigation_settings_companySettings_library = AppStates.root_core_navigation_settings_companySettings + '.library';
    AppStates.root_core_navigation_settings_companySettings_widget = AppStates.root_core_navigation_settings_companySettings + '.widget';
    // AppStates.root_core_navigation_settings_companySettings_bankInfo = AppStates.root_core_navigation_settings_companySettings + '.bankInfo';
    AppStates.root_core_navigation_settings_companySettings_automation = AppStates.root_core_navigation_settings_companySettings + '.automation';
    AppStates.root_core_navigation_settings_companySettings_quickbooks = AppStates.root_core_navigation_settings_companySettings + '.quickbooks';
    AppStates.root_core_navigation_bookkeeping_settings_company_quickbooks = AppStates.root_core_navigation_bookkeeping_settings_company + '.quickbooks';
    AppStates.root_core_navigation_settings_companySettings_setup = AppStates.root_core_navigation_settings_companySettings + '.setup';
    // AppStates.root_core_navigation_settings_companySettings_team = AppStates.root_core_navigation_settings_companySettings + '.team';
    // AppStates.root_core_navigation_settings_companySettings_subscription = AppStates.root_core_navigation_settings_companySettings + '.subscription';
    AppStates.root_core_navigation_settings_companySettings_new_general = AppStates.root_core_navigation_settings_companySettings + '.general';

    // NEW COMPANY SETTINGS
    AppStates.root_core_navigation_settings_company = AppStates.root_core_navigation_settings + '.company';
    AppStates.root_core_navigation_settings_mobile = AppStates.root_core_navigation_settings + '.mobile';
    AppStates.root_core_navigation_settings_company_general = AppStates.root_core_navigation_settings_company + '.general';
    AppStates.root_core_navigation_settings_company_integrations = AppStates.root_core_navigation_settings_company + '.integrations';
    AppStates.root_core_navigation_settings_company_branding = AppStates.root_core_navigation_settings_company + '.branding';
    AppStates.root_core_navigation_settings_company_client_portal = AppStates.root_core_navigation_settings_company + '.clientPortal';
    AppStates.root_core_navigation_settings_company_tax_information = AppStates.root_core_navigation_settings_company + '.taxInformation';
    AppStates.root_core_navigation_settings_company_team = AppStates.root_core_navigation_settings_company + '.team';
    AppStates.root_core_navigation_settings_company_account_access = AppStates.root_core_navigation_settings_company + '.accountAccess';
    AppStates.root_core_navigation_settings_company_subscription = AppStates.root_core_navigation_settings_company + '.subscription';
    AppStates.root_core_navigation_settings_company_bankInfo = AppStates.root_core_navigation_settings_company + '.bankInfo';
    AppStates.root_core_navigation_settings_company_paymentMethod = AppStates.root_core_navigation_settings_company + '.paymentMethod';
    AppStates.root_core_navigation_settings_company_mobile = AppStates.root_core_navigation_settings_company + '.mobile';
    AppStates.root_core_navigation_settings_company_preferences = AppStates.root_core_navigation_settings_company + '.notifications';
    AppStates.root_core_navigation_settings_company_ai_preferences = AppStates.root_core_navigation_settings_company + '.aiPreferences';
    AppStates.root_core_navigation_settings_company_reviews = AppStates.root_core_navigation_settings_company + '.reviews';
    AppStates.root_core_navigation_settings_company_account_setup = AppStates.root_core_navigation_settings_company + '.accountSetup';
    // For backwards compatibility (Needs to be removed)
    AppStates.root_core_navigation_settings_companySettings_overview = AppStates.root_core_navigation_settings_company_general;
    AppStates.root_core_navigation_settings_companySettings_bankInfo = AppStates.root_core_navigation_settings_company_bankInfo;
    AppStates.root_core_navigation_settings_companySettings_team = AppStates.root_core_navigation_settings_company_team;
    AppStates.root_core_navigation_settings_companySettings_subscription = AppStates.root_core_navigation_settings_company_subscription;
    // END

    AppStates.root_core_navigation_settings_company_client_portal_preview = AppStates.root_core + '.clientPortalPreview';


    AppStates.root_core_navigation_templates = AppStates.root_core_navigation + '.templates';
    AppStates.root_core_navigation_flows_template_gallery = AppStates.root_core_navigation + '.flowsTemplateGallery';
    AppStates.root_core_navigation_my_templates = AppStates.root_core_navigation + '.myTemplates';
    AppStates.root_core_navigation_my_templates_preview = AppStates.root_core_navigation_my_templates + '.preview-shared';
    AppStates.root_core_navigation_templatesEditor = AppStates.root_core_navigation + '.templatesEditor';
    AppStates.root_core_navigation_service_templates = AppStates.root_core_navigation + '.service_templates';

    AppStates.root_core_workspaceFile = AppStates.root_core + '.workspaceFile';
    AppStates.root_core_workspaceFileRedirection = AppStates.root_core + '.workspaceFileRedirection';
    AppStates.root_core_workspaceFilePaymentRedirection = AppStates.root_core + '.workspaceFilePaymentRedirection';
    AppStates.root_core_workspaceFile_stepProposal = AppStates.root_core + '.workspaceFileStepProposal';
    AppStates.root_core_workspaceFile_stepAgreement = AppStates.root_core + '.workspaceFileStepAgreement';
    AppStates.root_core_workspaceFile_stepPayment = AppStates.root_core + '.workspaceFileStepPayment';
    AppStates.root_core_workspaceFile_stepQuestionnaire = AppStates.root_core + '.workspaceFileStepQuestionnaire';
    AppStates.root_core_workspaceFile_brochure = AppStates.root_core + '.workspaceFileBrochure';
    AppStates.root_core_workspaceFile_timeline = AppStates.root_core + '.workspaceFileTimeline';
    AppStates.root_core_login = AppStates.root_core + '.login';
    AppStates.root_core_community_redirect = AppStates.root_core + '.community';

    AppStates.root_core_signup = AppStates.root_core + '.signup';
    AppStates.root_core_accountSetup = AppStates.root_core + '.account_setup';
    AppStates.root_core_pay = AppStates.root_core + '.pay';


    AppStates.root_core_linkExpired = AppStates.root_core + '.linkExpired';
    AppStates.root_core_clientLogin = AppStates.root_core + '.clientLogin';
    AppStates.root_core_resolveLink = AppStates.root_core + '.resolveLink';
    AppStates.root_core_community = AppStates.root_core + '.communityLogin';

    AppStates.root_core_receipt = AppStates.root_core + '.receipt';

    AppStates.root_core_questionnaireSubmitted = AppStates.root_core + '.questionnaireSubmitted';

    AppStates.root_core_setPassword = AppStates.root_core + '.setPassword';
    AppStates.root_core_resetPassword = AppStates.root_core + '.resetPassword';
    AppStates.root_core_resetPasswordForEvent = AppStates.root_core + '.resetPasswordForEvent';
    AppStates.root_core_resetPasswordForWorkspace = AppStates.root_core + '.resetPasswordForWorkspace';
    AppStates.root_core_resetPasswordForFile = AppStates.root_core + '.resetPasswordForFile';
    AppStates.root_core_resetPasswordForExternalSite = AppStates.root_core + '.resetPasswordForExternalSite';
    AppStates.root_core_resetPasswordForNetwork = AppStates.root_core + '.resetPasswordForNetwork';
    AppStates.root_core_resetPasswordForNetworkProject = AppStates.root_core + '.resetPasswordForNetworkProject';
    AppStates.root_core_resetPasswordForNetworkProfile = AppStates.root_core + '.resetPasswordForNetworkProfile';

    AppStates.root_core_admin = AppStates.root_core + '.admin';
    AppStates.root_core_admin_login = AppStates.root_core_admin + '.login';

    //Calendly integration
    AppStates.root_core_calendlyOAuth2Callback = AppStates.root_core + '.calendlyOAuth2Callback';

    //Intellum SSO integration
    AppStates.root_core_intellumSSORedirect = AppStates.root_core + '.intellumSSORedirect';

    //Google integration
    AppStates.root_core_googleOAuth2Callback = AppStates.root_core + '.googleOAuth2Callback';

    //Zoom integration
    AppStates.root_core_zoomOAuth2Callback = AppStates.root_core + '.zoomOAuth2Callback';

    //Nylas integration
    AppStates.root_core_nylasOAuth2Callback = AppStates.root_core + '.nylasOAuth2Callback';

    //QuickBooks integration
    AppStates.root_core_quickbooksOAuth2Callback = AppStates.root_core + '.quickbooksOAuth2Callback';

    //PicTime integration
    AppStates.root_core_pictimeOAuth2Callback = AppStates.root_core + '.pictimeOAuth2Callback';

    AppStates.root_core_navigation_onboardingSetupGuide = AppStates.root_core_navigation + '.onboardingSetupGuide';
    AppStates.root_core_navigation_yearInReview = AppStates.root_core + '.yearInReview';

    //Brands
    AppStates.root_core_navigation_brands = AppStates.root_core_navigation + '.brands';

    //Lifecycle Marketing
    AppStates.root_core_lifecycle_marketing_link = AppStates.root_core_navigation + '.lifecycleMarketingLink';

    //Process Mapping
    AppStates.root_core_navigation_process_mapping = AppStates.root_core_navigation + '.processMapping';
    AppStates.root_core_process_mapping_editor = AppStates.root_core + '.processMappingEditor';



    ///////////////////////////
    //     Malkut states     //
    ///////////////////////////
    AppStates.root_malkut = AppStates.root + '.malkut';
    AppStates.root_malkut_dashboard = AppStates.root_malkut + '.dashboard';
    AppStates.root_malkut_sales = AppStates.root_malkut + '.sales';
    AppStates.root_malkut_pricing = AppStates.root_malkut + '.pricing';
    AppStates.root_malkut_rts = AppStates.root_malkut + '.rts';
    AppStates.root_malkut_referrals = AppStates.root_malkut + '.referrals';
    AppStates.root_malkut_referrals_commissions = AppStates.root_malkut_referrals + '.commissions';
    AppStates.root_malkut_referrals_payouts = AppStates.root_malkut_referrals + '.payouts';
    AppStates.root_malkut_referrals_manual_referrals = AppStates.root_malkut_referrals + '.manual_referrals';
    AppStates.root_malkut_referrals_campaigns = AppStates.root_malkut_referrals + '.campaigns';
    AppStates.root_malkut_referrals_manage_coupons = AppStates.root_malkut_referrals + '.manage-coupons';
    AppStates.root_malkut_referrals_user = AppStates.root_malkut_referrals + '.user';
    AppStates.root_malkut_referrals_user_search = AppStates.root_malkut_referrals_user + '.search';
    AppStates.root_malkut_referrals_user_result = AppStates.root_malkut_referrals_user + '.result';
    AppStates.root_malkut_cs = AppStates.root_malkut + '.cs';
    AppStates.root_malkut_loans = AppStates.root_malkut + '.loans';
    AppStates.root_malkut_cs_companies = AppStates.root_malkut_cs + '.companies';
    AppStates.root_malkut_cs_contacts = AppStates.root_malkut_cs + '.contacts';
    AppStates.root_malkut_cs_userDetails = AppStates.root_malkut_cs + '.user_details';
    AppStates.root_malkut_cs_contactForm = AppStates.root_malkut_cs + '.contact_form';
    AppStates.root_malkut_cs_inboundMessages= AppStates.root_malkut_cs + '.inbound_messages';
    AppStates.root_malkut_cs_globalSearch = AppStates.root_malkut_cs + '.global_search';
    AppStates.root_malkut_cs_generalTasks = AppStates.root_malkut_cs + '.general_tasks';
    AppStates.root_malkut_cs_userDetails_search = AppStates.root_malkut_cs_userDetails + '.search';
    AppStates.root_malkut_cs_userDetails_result = AppStates.root_malkut_cs_userDetails + '.result';
    AppStates.root_malkut_trends = AppStates.root_malkut + '.trends';
    AppStates.root_malkut_failed_transfers_new = AppStates.root_malkut + '.failed_transfers_new';
    AppStates.root_malkut_unverified_recipients = AppStates.root_malkut + '.unverified_recipients';
    AppStates.root_malkut_managed_accounts = AppStates.root_malkut + '.managed_accounts';
    AppStates.root_malkut_unverified_objects = AppStates.root_malkut + '.unverified_objects';
    AppStates.root_malkut_graph = AppStates.root_malkut + '.graph';
    AppStates.root_malkut_disputed_payments = AppStates.root_malkut + '.disputed_payments';
    AppStates.root_malkut_administration = AppStates.root_malkut + '.administration';
    AppStates.root_malkut_public_payments_violations = AppStates.root_malkut + '.public_payments_violations';
    AppStates.root_malkut_restore_deleted_items = AppStates.root_malkut + '.restore_deleted_items';
    AppStates.root_malkut_cs_miscTools = AppStates.root_malkut_cs + '.misc_tools';
    AppStates.root_malkut_cs_flowTools = AppStates.root_malkut_cs + '.flow_tools';
    AppStates.root_malkut_cs_homepage = AppStates.root_malkut_cs + '.homepage';
    AppStates.root_malkut_email_statuses = AppStates.root_malkut + '.email_statuses';
    AppStates.root_malkut_pricingTiersDashboard = AppStates.root_malkut + '.pricing_tiers_dashboard';

    AppStates.root_core_plaid_oauth = AppStates.root_core_navigation + '.plaid_oauth';

    // New automations
    AppStates.root_core_navigation_newautomations = AppStates.root_core_navigation + '.newautomations';
    AppStates.root_core_navigation_newautomations_builder = AppStates.root_core_navigation_newautomations + '.newautomationsBuilder';
    AppStates.root_core_navigation_newautomations_execution_view = AppStates.root_core_navigation_newautomations + '.newautomationsExecutionView';
}]);
