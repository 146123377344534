(function () {
    "use strict";

    // @ngInject
    function ResetPasswordControllerCtor($scope, $injector, $stateParams, $state, $window,
                                         UsersManager,
                                         AppStates,
                                         AnalyticsService, PopupMessageService, RedirectService, ClientPortalService,
                                         WorkspaceFileService, AbTestService, Enums, InitialAppLoadParamsService,
                                         StatsigService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ResetPasswordController';

        this.UsersManager = UsersManager;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.RedirectService = RedirectService;
        this.$state = $state;
        this.ClientPortalService = ClientPortalService;
        var self = this;

        this.workspaceId = $stateParams.workspace_id;
        this.fileId = $stateParams.file_id;
        this.eventId = $stateParams.event_id;
        this.userId = $stateParams.user_id;
        this.auditeeCompanyId = $stateParams.auditee_company_id;
        this.groupId = $stateParams.group_id;
        this.guid = $stateParams.guid;
        this.isLoaded = false;
        this.isReactResetPassword = false;
        
        StatsigService.isGateEnabled('ng2react-login-ui')
            .then(function(result) {
                this.isReactResetPassword = result;
            }.bind(this))
            .finally(function() {
                this.isLoaded = true;
            }.bind(this));

        // Take care of sensitive data that came in the url
        if ($window.hb && $window.hb.resetPwd) {
            this.guid = this.guid || $window.hb.resetPwd.token;
            this.userId = this.userId || $window.hb.resetPwd.userId;
            $window.hb.resetPwd = null;
        }

        this.resetPasswordParams = {
            fileId: this.fileId,
            userId: this.userId,
            guid: this.guid,
            eventId: this.eventId
        }

        this.InitialAppLoadParamsService = InitialAppLoadParamsService;

        switch ($stateParams.link_source) {
            case 'client_send_invite_to_workspace':
                this.atwsUser = true;
                break;
            case 'vendor_was_referred':
                this.pvlReferred = true;
                break;
            case 'vendor_pvl_inquired_by_client':
                this.pvlInquired = true;
                break;
            default:
                this.pvlInquired = false;
                this.pvlReferred = false;
                this.atwsUser = false;
        }

        this.user = this.UsersManager.newUser();
        this.isNewUser = false;
        this.isSettingPassword = false;

        var analyticsArgs = {
            user_id: this.userId,
            file_id: this.fileId,
            workspace_id: this.workspaceId,
            auditee_company_id: this.auditeeCompanyId,
            event_id: this.eventId,
            group_id: this.groupId,
            guid: this.guid
        };

        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.open_reset_password, analyticsArgs);

        this._initClientPortal();

        this.gotUser = false;
        this.UsersManager.getUserByGuid(this.userId, this.guid, this.user, this.fileId, this.eventId).then(function success() {

            if (self.user.user_attribution.user_source_primary === 'product') {
                self.userFlowType = self.user.user_attribution.user_source_secondary;
            }

            self.event_name = self.user.event_name;
            delete self.user.event_name;

            self.event_date = self.user.event_date;
            delete self.user.event_date;

            self.gotUser = true;
            self.isNewUser = !self.user.last_login_time;
            analyticsArgs.email = self.user.email;
            if (self.isNewUser) {
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.get_user_for_create_password_success, analyticsArgs);

                if (self.user.create.profile_image && self.user.create.profile_image.original_url.indexOf('profilepic180x180.png') === -1) {
                    self.hasProfileImage = true;
                } else {
                    self.hasProfileImage = false;
                }

                var defaultImageName = 'yourlogohere.jpg';
                if (self.user.create.company.logo_image && self.user.create.company.logo_image.original_url.indexOf(defaultImageName) === -1) {
                    self.logoImage = self.user.create.company.logo_image.url;
                    self.hasLogoImage = true;
                } else {
                    self.hasLogoImage = false;
                }
            } else {
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.get_user_for_reset_password_success, analyticsArgs);
            }
        }, function error(resp) {
            self.gotUser = true;
            self.PopupMessageService.showErrorAlert(resp.data.error_message);
            self.goToState(self.AppStates.root_core_login);
            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.get_user_for_reset_password_error, analyticsArgs);
        });

        this.loginParams = {
            loginSuccess: this.resetPwSocialSuccess.bind(this),
            loginError: this.resetPwError.bind(this),
            user: this.user
        };

        if ($state.current.name && $state.current.name.indexOf(AppStates.root_core_setPassword) !== -1) {
            this.headerTitle = 'SET_PASSWORD._HEADER_';
        } else {
            this.headerTitle = 'RESET_PASSWORD.EXISTING_USER._HEADER_';
        }
    }

    Controllers.ResetPasswordController = Class(Controllers.BaseController, {

        constructor: ResetPasswordControllerCtor,

        resetUserPassword: function resetUserPassword(form) {

            form.$setSubmitted();

            if (!form.$valid) {
                return;
            }

            this.isSettingPassword = true;

            var analyticsArgs = {
                user_id: this.userId,
                auditee_company_id: this.auditeeCompanyId,
                email: this.user.email
            };

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.reset_password_request, analyticsArgs);

            this.UsersManager.resetUserPassword(this.userId, this.guid, this.newPassword, this.user).then(
                this.resetPwSuccess.bind(this),
                this.resetPwError.bind(this)
            );
        },

        resetPwSuccess: function loginSuccess() {

            var analyticsArgs = {
                user_id: this.userId,
                email: this.user.email
            };

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.reset_password_success, analyticsArgs);

            this.$state.go(this.AppStates.root_core_login);

        },

        resetPwError: function loginError(resp) {
            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.reset_password, resp);
            this.errorSettingPassword = resp.data.error_message;

            this.isSettingPassword = false;
        },

        resetPwSocialSuccess: function resetPwSocialSuccess() {

            var analyticsArgs = {
                user_id: this.userId,
                email: this.user.email
            };

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.reset_password_success, analyticsArgs);

            var shouldStartTrial = this.InitialAppLoadParamsService.getParam('target') === 'trial';
            if (shouldStartTrial){
                this.$state.go(this.AppStates.root_core_navigation_pipeline);
            }
            else
            {
                this.RedirectService.redirect(this.user);
            }

        },

        _initClientPortal: function _initClientPortal() {
            this.portalModel = this.ClientPortalService.getConfiguration();
            this.isInClientPortal = this.ClientPortalService.isInClientPortal();
        }
    });
}());
