(function () {
    'use strict';

    class FlowsOnboardingWizardController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            _,
            Enums,
            AbTestService,
            OnboardingService,
            FeaturesService,
            AnalyticsService,
            CompaniesManager,
            UsersManager,
            $translate,
            FlowService,
            DeviceService,
            UiPersistenceService,
            $timeout,
            $window,
            IntentService,
            InstantBrandingService
        ) {
            super($scope, $injector);
            this.__objectType = 'FlowsOnboardingWizardController';

            this.$scope = $scope;
            this.$window = $window;
            this._ = _;
            this.Enums = Enums;
            this.AbTestService = AbTestService;
            this.OnboardingService = OnboardingService;
            this.AnalyticsService = AnalyticsService;
            this.FeaturesService = FeaturesService;
            this.CompaniesManager = CompaniesManager;
            this.UsersManager = UsersManager;
            this.$translate = $translate;
            this.FlowService = FlowService;
            this.DeviceService = DeviceService;
            this.UiPersistenceService = UiPersistenceService;
            this.$timeout = $timeout;
            this.IntentService = IntentService;
            this.isBrandingStepDirty = false;
            this.InstantBrandingService = InstantBrandingService;

            this.user = this.UsersManager.getCurrUser();
            this.isDesktop = !this.DeviceService.nxSmallBreakpoint();
            this.isMobile = this.DeviceService.nxSmallBreakpoint();
            this.isMweb = this.isMobile && !this.DeviceService.isInAppBrowser();
            this.t1ActivationABCVariant = this.OnboardingService.getT1ActivationABCVariant();
            this.isAbcVariantControl = this.t1ActivationABCVariant === 'control' || !this.t1ActivationABCVariant;
            this.homePaymentsCardVariant = this.user.getExistingAbTestVariation('home_payments_poc');

            this.isContractOnboarding = this.IntentService.shouldGetContractOnboarding();


            this.watcherDeregistrationFn = [];

            this.watcherDeregistrationFn.push($scope.$watch('$ctrl.steps',
                (steps) => {
                    this.updateCurrStepIndex();
                }
            ));

            this.watcherDeregistrationFn.push($scope.$watch('$ctrl.selectedFlowTemplateId', (currSelectedTemplateId, prevSelectedTemplateId) => {
                if (currSelectedTemplateId && currSelectedTemplateId !== prevSelectedTemplateId) {
                    this.$timeout(() => {
                        this.scrollToOnboardingTop();
                    }, 0);
                }
            }));

            this.watcherDeregistrationFn.push($scope.$watch('$ctrl.isCollapsed', (isCollapsed, wasCollapsed) => {
                if (isCollapsed === false && wasCollapsed === true) {
                    this.updateCurrStepIndex();
                }
            }));

            this.watcherDeregistrationFn.push($scope.$watch('$ctrl.isBrandingStepDirty', (isBrandingStepDirty, wasBrandingStepDirty) => {
                if (isBrandingStepDirty !== wasBrandingStepDirty) {
                    this.firstStepCTA = this.$translate.instant(this.getFirstStepCTA());
                }
            }));

            $scope.$on('$destroy', () => {
                this.watcherDeregistrationFn.forEach(deregisterFunction => deregisterFunction());
            });
        }

        getDataFromUIPersistence(key) {
            let persistKey = this.Enums.flowsOnboardingWizardSteps.templates;
            
            switch (this.t1ActivationABCVariant) {
                case 'skip_template':
                    persistKey = this.Enums.flowsOnboardingWizardSteps.services;
                    break;
                case 'legacy_like':
                    persistKey = this.Enums.flowsOnboardingWizardSteps.legacyServices;
                    break;
                default:
                    break;
            }
            const storedData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.flowsOnboarding, {});
            return storedData && storedData[persistKey] && storedData[persistKey][key];
        }

        $onInit() {
            this.company = this.CompaniesManager.getCurrCompany();
            this.defaultCoverImage = this.company.getDefaultCoverImage();

            this.currentStepIndex = 0;
            this.selectedServiceKey = null;
            this.selectedFlowTemplateId = null;
            this.selectedFlowTemplateImageUrl = null;
            this.selectedFlowTemplateTitle = null;
            this.isBlankTemplate = null;

            this.isBrandingStepValid = false;
            this.isServicesStepValid = false;
            this.isTemplatesStepValid = false;
            this.isTemplatesReadyStepValid = true;

            this.firstStepCTA = this.$translate.instant(this.getFirstStepCTA());
            this.flowInstanceId = this.getDataFromUIPersistence('flowInstanceId');
        }

        updateCurrStepIndex() {
            if (this.steps) {
                this.currentStepIndex = this._.findIndex(this.steps, step => step.key === this.currentStep.key);
            }
        }

        submitStep(step) {
            this.getNextButtonAnalyticsArgs().then(nextButtonAnalyticsArgs => {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.setup_guide_next, nextButtonAnalyticsArgs);
                // let's inform all steps that submit was fired
                this.$scope.$broadcast('submitOnboardingStep', step);
                if (this.isLastStep()) {
                    // I want `onStepComplete` to happen only after there is instance id.
                    // Hence I dont want to do nothing but broadcasting that step was clicked.
                    // The step itself will handle `onStepComplete`.
                    return;
                }

                var isCurrentStepFirst = this.isFirstStep();

                if (step.status === 'complete') {
                    // if the step was already completed - just go next
                    this.moveToNextStep();
                } else {
                    // if the step is completing now, update the db and the onboardingSequence
                    this.onStepComplete();
                }

                if (isCurrentStepFirst) {
                    this.InstantBrandingService.showSatisfactionSurveyIfNeeded();
                }

                this.scrollToOnboardingTop();
            });
        }

        scrollToOnboardingTop () {
            const flowsOnboardingSection = document.getElementById("flowsOnboardingSection");
            let scrollIntoViewOptions = { behavior: "smooth", block: "end", inline: "nearest" };
            if (this.DeviceService.isSafari() || this.DeviceService.isEdge()) {
                // options is not supported by safari and edge
                scrollIntoViewOptions = false;
            }
            flowsOnboardingSection.scrollIntoView(scrollIntoViewOptions);
        }

        isStepInvalid() {
            const validationFunctions = {
                [this.Enums.flowsOnboardingWizardSteps.branding] : () => { return this.isBrandingStepValid },
                [this.Enums.flowsOnboardingWizardSteps.services] : () => { return this.isServicesStepValid },
                [this.Enums.flowsOnboardingWizardSteps.legacyServices] : () => { return this.isServicesStepValid },
                [this.Enums.flowsOnboardingWizardSteps.templates]: () => { return this.isTemplatesStepValid },
                [this.Enums.flowsOnboardingWizardSteps.selected]: () => { return this.isTemplatesReadyStepValid }
            }
            const isStepValid = validationFunctions[this.currentStep.key] ? validationFunctions[this.currentStep.key]() : true;
            return !isStepValid;
        }

        getMainCtaText() {
            if (this.isLastStepMobile()) {
                return this.$translate.instant('FLOWS_ONBOARDING._CONTINUE_EXPLORING_');
            } else if (this.isFirstStep()) {
                return this.firstStepCTA;
            } else {
                return this.$translate.instant('FLOWS_ONBOARDING._CONTINUE_');
            }
        }

        $onChanges(changesObj) {
            if (this.currentStep && this.onboardingType) {
                this.InstantBrandingService.getFetchedSuggestedAssetsAnalyticsArgs().then(instantBrandingAnalyticsArgs => {
                    const analyticsArgs = {
                        step: this.currentStep.key,
                        phase: 'onboarding',
                        onboarding_type: this.onboardingType,
                        onboarding_abtest_name: this.onboardingAbTest && this.onboardingAbTest.name,
                        onboarding_abtest_variant: this.onboardingAbTest && this.onboardingAbTest.variant,
                        home_poc_abtest_variant: this.homePaymentsCardVariant
                    };

                    this.AnalyticsService.trackPageView(
                        this,
                        this.AnalyticsService.analytics_events.setup_guide,
                        Object.assign(analyticsArgs, instantBrandingAnalyticsArgs)
                    );
                });
            }
        }

        getNextButtonAnalyticsArgs() {
            return this.InstantBrandingService.getSavedSuggestedAssetsAnalyticsArgs().then(instantBrandingSummaryAnalyticsArgs => {
                const analyticsArgs = {
                    step: this.currentStep.key,
                    cta_variant: this.firstCtaVariant,
                    service_flow: this.selectedServiceKey,
                    template_id: this.selectedFlowTemplateId,
                    template_title: this.selectedFlowTemplateTitle,
                    onboarding_type: this.onboardingType,
                    onboarding_abtest_name: this.onboardingAbTest && this.onboardingAbTest.name,
                    onboarding_abtest_variant: this.onboardingAbTest && this.onboardingAbTest.variant
                };
                return Object.assign(analyticsArgs, instantBrandingSummaryAnalyticsArgs)
            });
        }

        onStepComplete() {
            this.OnboardingService.completeOnboardingStep(this.currentStep);
        }

        shouldSeeBackButton() {
            return (this.currentStepIndex > 0) && !this.isLastStep();
        }

        shouldSeeNextButton() {
            if (this.steps) {
                return (this.currentStepIndex < this.steps.length - 1) || this.isLastStepMobile(); 
            }
            return false;
        }

        isFirstStep() {
            return this.currentStepIndex === 0;
        }

        getFirstStepCTA() {
            let ctaText = 'FLOWS_ONBOARDING._CONTINUE_';

            if (!this.isMobile && !this.isBrandingStepDirty) {
                ctaText = 'FLOWS_ONBOARDING._LATER_';
            }

            return ctaText;
        }

        isLastStep() {
            return this.currentStepIndex === this.steps.length - 1;
        }

        isLastStepMobile() {
            return (
                this.isLastStep()
                &&
                this.isMobile
            );
        }


        moveToPreviousStep() {
            this.currentStepIndex--;
            this.currentStep = this.steps[this.currentStepIndex];
            this.AnalyticsService.track(this, 'setup guide back', {
                step: this.currentStep.key,
                onboarding_type: this.onboardingType,
                onboarding_abtest_name: this.onboardingAbTest && this.onboardingAbTest.name,
                onboarding_abtest_variant: this.onboardingAbTest && this.onboardingAbTest.variant});
        }

        moveToNextStep() {
            this.currentStepIndex++;
            this.currentStep = this.steps[this.currentStepIndex];
        }

        getStepFromKey(stepKey) {
            if (this.steps) {
                return this._.find(this.steps, step => step.key === stepKey);
            }
        }

        shouldRenderStep(stepKey) {
            const thisStep = this.getStepFromKey(stepKey);
            if (thisStep) {
                const diff = this.currentStep.order - thisStep.order;
                return Math.abs(diff) <= 1;
            }
        }

        isPrecedingStep(stepKey) {
            const thisStep = this.getStepFromKey(stepKey);
            if (thisStep) {
                return thisStep.order < this.currentStep.order;
            }
        }

        isSucceedingStep(stepKey) {
            const thisStep = this.getStepFromKey(stepKey);
            if (thisStep) {
                return thisStep.order > this.currentStep.order;
            }
        }

        useYourOwnContract(){
            this.$scope.$broadcast('useYourOwnContract');
            this.submitStep(this.currentStep);
        }
    }

    Components.FlowsOnboardingWizard = {
        controller: FlowsOnboardingWizardController,
        bindings: {
            steps: '<',
            onboardingType: '<',
            onboardingAbTest: '<',
            currentStep: '=',
            isCollapsed: '<',
            completeMwebWizard: '&',
            stepTranslationMap: '<'
        },
        templateUrl: 'angular/app/modules/core/features/onboarding/flows_onboarding/flows_onboarding_wizard/flows_onboarding_wizard.html',
    };
}());
