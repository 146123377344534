/**
 * Created by inonstelman on 12/28/14.
 */

// @ngInject

Models.CompanyModel = function CompanyModel(BaseModel, _, AnalyticsService, CompanyService, CompaniesManager, TemplatesManager, UsersManager, WorkspaceFilesManager, ImageService, Enums, FeaturesService) {
    var CompanyModel = Class(BaseModel, {

        constructor: function constructor() {
            this.__className = 'CompanyModel';
        },

        afterMixingFrom: function afterMixingFrom() {
            if (this.team_members) {
                this.team_members.forEach(function(team_member) {
                    team_member.company._id = this._id;
                }.bind(this));
            }

            if (this.owner) {
                this.owner.company._id = this._id;
            }
        },

        mapChilds: function mapChilds() {
            return {
                owner: 'UserModel',
                vendor_packages: 'PackagesCollection',
                agreements: 'AgreementsCollection',
                questionnaires: 'QuestionnairesCollection',
                vendor_emails: 'EmailsCollection',
                brochure_containers: 'BrochureProposalsContainersCollection',
                invoices: 'InvoicesCollection',
                proposals: 'ProposalsCollection',
                contact_forms: 'ContactFormsCollection',
                brochure_templates: 'TemplatesCollection',
                team_members: 'UsersCollection'
            };
        },

        /**
         * Override this.cachable when you don't want the models to be cached (always fetched from server)
         */
        isCachable: function isCachable() {
            return false;
        },

        getOverviewJSON: function overviewJSON() {
            return {
                company_name: this.company_name,
                about: this.about,
                description: this.description,
                vimeo_url: this.vimeo_url,
                blog_url: this.blog_url,
                website_url: this.website_url,
                company_type: this.company_type,
                address: this.address,
                street_address: this.street_address,
                city: this.city,
                state: this.state,
                default_timezone: this.default_timezone,
                zip_code: this.zip_code,
                phone_number: this.phone_number,
                email: this.email,
                color: this.color,
                twitter_url: this.twitter_url,
                facebook_url: this.facebook_url,
                instagram_url: this.instagram_url,
                pinterest_url: this.pinterest_url,
                linkedin_url: this.linkedin_url,
                dribbble_url: this.dribbble_url,
                tiktok_url: this.tiktok_url,
                other_url: this.other_url,
                behance_url: this.behance_url,
                hb_url: this.hb_url,
                brand_color: this.brand_color,
                text_color: this.text_color,
                other_company_type: this.other_company_type,
                country: this.country
            };
        },

        _dataOnlyInterceptor: function _dataOnlyInterceptor(copy) {
            delete copy.contracts;
            delete copy.vendor_emails;
            delete copy.agreements;
            delete copy.vendor_packages;
            delete copy.payments_containers;
            delete copy.testimonials;
            delete copy.workflow_automation_info;
            delete copy.user_pipeline_stages;
            delete copy.proposal_email_for_vendor;
            delete copy.questionnaire;
            delete copy.questionnaires;
            delete copy.new_lead_email;
            delete copy.brochure_containers;
            delete copy.setup_assets;
            delete copy.company_spaces;
            delete copy.proposals;
            delete copy.invoices;
            delete copy.vendor_proposals;
            delete copy.contact_forms;
            delete copy.brochure_templates;
            delete copy.flows;
            delete copy.email_signatures;
            delete copy.cover_images;
        },

        isNonEventType: function isNonEventType() {
            var simpleFormVendors = ['web_designer', 'interior_designer', 'graphic_designer', 'marketing', 'business_consultant', 'visual_artist', 'maker_artisan', 'blogger', 'fashion'];
            return simpleFormVendors.indexOf(this.company_type) > -1;
        },

        // ==============================
        // Invoice & Proposal Templates
        // ==============================

        updatePayableCoverImage: function updatePayableCoverImage(payable, image) {
            return TemplatesManager.updatePayableCoverImage(this, payable, image);
        },

        updatePayableProposal: function updatePayableProposal(invoice, proposalData) {
            return TemplatesManager.updatePayableProposal(this, invoice, proposalData);
        },

        togglePayableAllPackagesTax: function togglePayableAllPackagesTax(invoice, addTax) {
            return TemplatesManager.togglePayableAllPackagesTax(this, invoice, addTax);
        },

        togglePayableAllPackagesSvc: function togglePayableAllPackagesSvc(invoice, addSvc) {
            return TemplatesManager.togglePayableAllPackagesSvc(this, invoice, addSvc);
        },

        addPayablePackage: function addPayablePackage(payable, packageIdToAdd) {
            return TemplatesManager.addPayablePackage(this, payable, packageIdToAdd);
        },

        updatePayablePackage: function updatePayablePackage(payable, packageModel) {
            return TemplatesManager.updatePayablePackage(this, payable, packageModel);
        },

        deletePayablePackage: function deletePayablePackage(payable, packageIdToDelete) {
            return TemplatesManager.deletePayablePackage(this, payable, packageIdToDelete);
        },

        addPayableService: function addPayableService(payable, serviceModel) {
            return TemplatesManager.addPayableService(this, payable, serviceModel);
        },

        addPayablePackageService: function addPayablePackageService(payable, packageModel, serviceModel) {
            return TemplatesManager.addPayablePackageService(this, payable, packageModel, serviceModel);
        },

        updatePayablePackageService: function updatePayablePackageService(payable, packageModel, serviceModel) {
            return TemplatesManager.updatePayablePackageService(this, payable, packageModel, serviceModel);
        },

        deletePayablePackageService: function deletePayablePackageService(payable, packageModel, serviceModel) {
            return TemplatesManager.deletePayablePackageService(this, payable, packageModel, serviceModel);
        },

        updatePayablePackageServiceImage: function updatePayablePackageServiceImage(payable, serviceModel, image, packageModel) {
            return TemplatesManager.updatePayablePackageServiceImage(this, payable, serviceModel, image, packageModel);
        },

        deletePayablePackageServiceImage: function deletePayablePackageServiceImage(payable, packageModel, serviceModel) {
            return TemplatesManager.deletePayablePackageServiceImage(this, payable, packageModel, serviceModel);
        },

        updatePayableServicesOrder: function updatePayableServicesOrder(payable, packageModel) {
            return TemplatesManager.updatePayableServicesOrder(this, payable, packageModel);
        },

        addPayablePayment: function addPayablePayment(payable) {
            return TemplatesManager.addPayablePayment(this, payable);
        },

        updatePayablePayment: function updatePayablePayment(payable, payment) {
            return TemplatesManager.updatePayablePayment(this, payable, payment);
        },

        deletePayablePayment: function deletePayablePayment(payable, payment) {
            return TemplatesManager.deletePayablePayment(this, payable, payment);
        },

        savePaymentsContainerAsTemplate: function savePaymentsContainerAsTemplate(payable, templateTitle) {
            return TemplatesManager.savePaymentsContainerAsTemplate(this, payable, templateTitle);
        },

        updatePaymentsByTemplate: function updatePaymentsByTemplate(payable, payment) {
            return TemplatesManager.updatePaymentsByTemplate(this, payable, payment);
        },

        updatePayableAgreement: function updatePayableAgreement(payable) {
            return TemplatesManager.updatePayableAgreement(this, payable);
        },

        updatePayableAgreementByTemplate: function updatePayableAgreementByTemplate(payable, templateId) {
            return TemplatesManager.updatePayableAgreementByTemplate(this, payable, templateId);
        },

        savePayableAgreementAsTemplate: function savePayableAgreementAsTemplate(payable, templateTitle) {
            return TemplatesManager.savePayableAgreementAsTemplate(this, payable, templateTitle);
        },

        togglePayablePaymentScheduleType: function togglePayablePaymentScheduleType(payable, isRecurring) {
            return TemplatesManager.togglePayablePaymentScheduleType(this, payable, isRecurring);
        },

        updatePayableRecurringPayment: function updatePayableRecurringPayment(payable, startDate, endDate) {
            return TemplatesManager.updatePayableRecurringPayment(this, payable, startDate, endDate);
        },

        calculateProposalTax: function calculateProposalTax(payable, type, tax, taxOrSvc) {
            return TemplatesManager.calculateProposalTax(this, payable, type, tax, taxOrSvc);
        },

        updatedDefaultTax: function updatedDefaultTax(tax, taxTitle) {
            return CompaniesManager.updatedDefaultTax(this, tax, taxTitle);
        },

        getDefaultTax: function getDefaultTax() {
            return this.default_tax;
        },

        updatedDefaultSVC: function updatedDefaultSVC(tax) {
            return CompaniesManager.updatedDefaultSVC(this, tax);
        },

        getDefaultSVC: function getDefaultSVC() {
            return this.default_svc;
        },

        isDefaultCompanyLogo: function isDefaultCompanyLogo() {
            return CompanyService.isDefaultCompanyLogo(this);
        },

        isDefaultCompanyIcon: function isDefaultCompanyIcon() {
            return CompanyService.isDefaultCompanyIcon(this);
        },

        getProposalTemplateTitle: function getProposalTemplateTitle(id) {
            return _.find(this.vendor_proposals, function (obj) {
                return obj._id === id;
            });
        },

        getPaymentsTemplateTitle: function getPaymentsTemplateTitle(id) {
            return _.find(this.payments_containers, function (obj) {
                return obj._id === id;
            });
        },

        getAgreementTemplateTitle: function getAgreementTemplateTitle(id) {
            return _.find(this.agreements, function (obj) {
                return obj._id === id;
            });
        },

        getQuestionnaireTemplateTitle: function getQuestionnaireTemplateTitle(id) {
            return _.find(this.questionnaires, function (obj) {
                return obj._id === id;
            });
        },

        // TEMPLATES

        //File
        updatePackage: function updatePackage(packageModel, callingScope) {
            var analyticsArgs = {
                company_id: this.id(),
                template_type: 'package',
                template_id: packageModel.id()
            };

            var promise = TemplatesManager.updatePackage(this, packageModel);
            promise.then(function success() {
                AnalyticsService.track(callingScope, AnalyticsService.analytics_events.update_template_success, analyticsArgs);
            }, function failed(resp) {
                AnalyticsService.track(callingScope, AnalyticsService.analytics_events.update_template_error, analyticsArgs);
            });
            return promise;
        },

        //NOTE: sortedProjectTypes doesn't persist when editing types.
        sortedProjectTypes: function sortedProjectTypes() {

            if (!this.project_types) {
                return [];
            }
            var defaultType = [];
            if (this.default_project_type_label) {
                var defaultTypeIndex = this.project_types.findIndex(function(projectType) {
                    return projectType.label.toLowerCase() === this.default_project_type_label.toLowerCase();
                }, this);
                defaultType = this.project_types.slice(defaultTypeIndex, defaultTypeIndex + 1);
            }

            var editableTypes = _.filter(this.project_types, function(p) { return p.editable; });
            var nonEditableTypes = _.reject(this.project_types, function(p) { return p.editable; });

            // sort by name
            editableTypes = _.sortBy(editableTypes, function(p) { return p.label; } );
            nonEditableTypes = _.sortBy(nonEditableTypes, function(p) { return p.label; } );

            return _.union(defaultType, editableTypes, nonEditableTypes);
        },

        projectTypesSort: function projectTypesSort() {
            if (!this.project_types) {return [];}

            var editableTypes = [];
            var nonEditableTypes = [];

            this.project_types.forEach(function (type) {
                if (type.editable) {
                  editableTypes.push(type);
                } else {
                  nonEditableTypes.push(type);
                }
            });

            return editableTypes.concat(nonEditableTypes);
        },

        sortedLeadSources: function sortedLeadSources() {
            if (!this.lead_sources) {
                return [];
            }
            var editableTypes = _.filter(this.lead_sources, function(p) { return p.editable; });
            var nonEditableTypes = _.reject(this.lead_sources, function(p) { return p.editable; });
            return _.union(editableTypes, nonEditableTypes);

        },

        updateService: function updateService(serviceModel, packageModel) {
            return TemplatesManager.updateService(this, serviceModel, packageModel);
        },

        updateServiceImage: function updateServiceImage(serviceModel, image, packageModel) {
            return TemplatesManager.updateServiceImage(this, serviceModel, packageModel, image);
        },

        deleteServiceImage: function deleteServiceImage(serviceModel, packageModel) {
            return TemplatesManager.deleteServiceImage(this, serviceModel, packageModel);
        },

        updateServicesOrder: function updateServicesOrder(packageModel) {
            return TemplatesManager.updateServicesOrder(this, packageModel);
        },

        updateVendorPackagesOrder: function updateVendorPackagesOrder(peyable,packagesOrder) {
            return TemplatesManager.updateVendorPackagesOrder(this,peyable, packagesOrder);
        },

        updateServicesOrderInALaCarte: function updateServicesOrderInALaCarte(brochureContainer, aLaCarteModel) {
            return TemplatesManager.updateServicesOrderInALaCarte(this, brochureContainer, aLaCarteModel);
        },

        updateBrochureALaCarteImage: function updateBrochureALaCarteImage(brochureContainer, image) {
            return TemplatesManager.updateBrochureALaCarteImage(this, brochureContainer, image);
        },

        deletePackage: function deletePackage(packageModel) {

            return TemplatesManager.deletePackage(this, packageModel);
        },

        togglePackageTax: function togglePackageTax(packageModel, taxAll) {
            return TemplatesManager.togglePackageTax(this, packageModel, taxAll);
        },

        togglePackageSvc: function togglePackageSvc(packageModel, svcAll) {
            return TemplatesManager.togglePackageSvc(this, packageModel, svcAll);
        },

        deleteService: function deleteService(serviceModel, packageModel) {
            return TemplatesManager.deleteService(this, serviceModel, packageModel);
        },

        addAdditionalServiceToPackage: function addAdditionalServiceToPackage(packageModel, newService) {
            return TemplatesManager.addAdditionalServiceToPackage(this, packageModel, newService);
        },

        shouldShowTax: function () {
            return true;
        },

        shouldShowSVC: function () {
            return this.isVenue();
        },

        moveService: function moveService(payable, serviceId, sourcePackageId, destPackageId, servicesCollection) {
            return TemplatesManager.moveService(this, payable, serviceId, sourcePackageId, destPackageId, servicesCollection);
        },

        updatePackagesAndServicesOrder: function(payable, order) {
            return TemplatesManager.updatePackagesAndServicesOrder(this, payable, order);
        },

        /**Questionnaire**/
        updateQuestionnaireCoverImage: function updateQuestionnaireCoverImage(questionnaire, image) {
            return TemplatesManager.updateQuestionnaireCoverImage(this, questionnaire, image);
        },

        createQuestion: function createQuestion(questionnaire, question) {
            return TemplatesManager.createQuestion(this, questionnaire, question);
        },

        createEventQuestions: function createEventQuestions(questionnaire) {
            return TemplatesManager.createEventQuestions(this, questionnaire);
        },

        updateQuestion: function updateQuestion(questionnaire, question) {
            return TemplatesManager.updateQuestion(this, questionnaire, question);
        },

        updateQuestionsOrder: function updateQuestionOrder(questionnaire) {
            return TemplatesManager.updateQuestionsOrder(this, questionnaire);
        },

        deleteQuestion: function deleteQuestion(questionnaire, question) {
            return TemplatesManager.deleteQuestion(this, questionnaire, question);
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return {
                company_id: this._id,
                company_name: this.company_name
            };
        },

        updateAgreement: function updateAgreement(agreement, callingScope) {

            var analyticsArgs = {
                company_id: this.id(),
                template_type: 'agreement',
                template_id: agreement.id()
            };

            var promise = TemplatesManager.updateAgreement(this, agreement);
            promise.then(function success() {
                AnalyticsService.track(callingScope, AnalyticsService.analytics_events.update_template_success, analyticsArgs);
            }, function failed(resp) {
                analyticsArgs.error_status = resp.status;
                analyticsArgs.error_data = resp.data;
                analyticsArgs.error_status_text = resp.statusText;
                analyticsArgs.error_headers = resp.headers;
                AnalyticsService.track(callingScope, AnalyticsService.analytics_events.update_template_error, analyticsArgs);
            });
            return promise;
        },

        updateAgreementCoverImage: function updateAgreementCoverImage(agreement, image) {
            return TemplatesManager.updateAgreementCoverImage(this, agreement, image);
        },

        updatePayable: function updatePayable(payable) {
            return TemplatesManager.updatePayable(this, payable);
        },

        updateAgreementWithFile: function updateAgreementWithFile(agreementModel, fileBin) {
            return TemplatesManager.updateAgreementWithFile(this, agreementModel, fileBin);
        },

        updateQuestionnaire: function updateQuestionnaire(questionnaire, callingScope) {
            var analyticsArgs = {
                company_id: this.id(),
                template_type: 'questionnaire',
                template_id: questionnaire.id()

            };

            var promise = TemplatesManager.updateQuestionnaire(this, questionnaire);
            promise.then(function success() {
                AnalyticsService.trackSuccess(callingScope, AnalyticsService.analytics_events.update_template_success, analyticsArgs);
            }, function failed(resp) {
                AnalyticsService.trackError(callingScope, AnalyticsService.analytics_events.update_template_success, analyticsArgs);
            });
            return promise;
        },

        updateEmail: function updateEmail(email, callingScope) {
            var analyticsArgs = {
                company_id: this.id(),
                template_type: 'email',
                template_id: email.id()
            };

            var promise = TemplatesManager.updateEmail(this, email);
            promise.then(function success() {
                AnalyticsService.track(callingScope, AnalyticsService.analytics_events.update_template_success, analyticsArgs);
            }, function failed(resp) {
                AnalyticsService.track(callingScope, AnalyticsService.analytics_events.update_template_error, analyticsArgs);
            });
            return promise;
        },

        /*****************************************
         * BROCHURE ACTIONS
         ******************************************/

        updateBrochure: function updateBrochure(brochure, callingScope) {
            var analyticsArgs = {
                company_id: this.id(),
                template_type: 'brochure',
                template_id: brochure.id()
            };

            var promise = TemplatesManager.updateBrochure(this, brochure);
            promise.then(function success() {
                AnalyticsService.track(callingScope, AnalyticsService.analytics_events.update_template_success, analyticsArgs);
            }, function failed(resp) {
                AnalyticsService.track(callingScope, AnalyticsService.analytics_events.update_template_error, analyticsArgs);
            });
            return promise;
        },

        createBrochureProposal: function createBrochureProposal(brochureContainer) {
            return TemplatesManager.createBrochureProposal(this, brochureContainer);
        },

        deleteBrochureProposal: function deleteBrochureProposal(brochureProposal, brochureContainer) {
            return TemplatesManager.deleteBrochureProposal(this, brochureProposal, brochureContainer);
        },

        createBrochureALaCarteProposal: function createBrochureALaCarteProposal(brochureContainer) {
            return TemplatesManager.createBrochureALaCarteProposal(this, brochureContainer);
        },

        deleteBrochureALaCarteProposal: function deleteBrochureALaCarteProposal(brochureContainer) {
            return TemplatesManager.deleteBrochureALaCarteProposal(this, brochureContainer);
        },

        createBrochureGreeting: function createBrochureGreeting(brochure) {
            return TemplatesManager.createBrochureGreeting(this, brochure);
        },

        updateBrochureGreeting: function updateBrochureGreeting(brochure) {
            return TemplatesManager.updateBrochureGreeting(this, brochure);
        },

        deleteBrochureGreeting: function deleteBrochureGreeting(brochure) {
            return TemplatesManager.deleteBrochureGreeting(this, brochure);
        },

        updateBrochureGreetingImage: function updateBrochureGreetingImage(brochure, image) {
            return TemplatesManager.updateBrochureGreetingImage(this, brochure, image);
        },

        updateBrochureProposal: function updateBrochureProposal(brochureContainer, brochureProposal) {
            return TemplatesManager.updateBrochureProposal(this, brochureContainer, brochureProposal);
        },

        updateBrochureALaCarte: function updateBrochureALaCarte(brochureContainer) {
            return TemplatesManager.updateBrochureALaCarte(this, brochureContainer);
        },

        updateBrochureProposalsOrder: function updateBrochureProposalsOrder(brochure, proposalsOrder) {
            return TemplatesManager.updateBrochureProposalsOrder(this, brochure, proposalsOrder);
        },

        updateBrochureServicesOrder: function updateBrochureServicesOrder(brochureContainer, brochureProposal, packageModel) {
            return TemplatesManager.updateBrochureServicesOrder(this, brochureContainer, brochureProposal, packageModel);
        },

        addPackageToBrochure: function addPackageToBrochure(brochureContainer, brochureProposal, packageToAddId) {
            return TemplatesManager.addPackageToBrochure(this, brochureContainer, brochureProposal, packageToAddId);
        },

        addAdditionalServiceToPackageInBrochure: function addAdditionalServiceToPackageInBrochure(brochureContainer, proposal, packageModel, newService) {
            return TemplatesManager.addAdditionalServiceToPackageInBrochure(this, brochureContainer, proposal, packageModel, newService);
        },

        addCustomItemToBrochure: function addCustomItemToBrochure(brochureContainer, proposal, itemData) {
            return TemplatesManager.addCustomItemToBrochure(this, brochureContainer, proposal, itemData);
        },

        deleteServiceFromBrochure: function deleteServiceFromBrochure(brochureContainer, proposal, serviceModel, packageModel) {
            return TemplatesManager.deleteServiceFromBrochure(this, brochureContainer, proposal, serviceModel, packageModel);
        },

        deletePackageFromBrochure: function deletePackageFromBrochure(brochureContainer, proposal, packageModel) {
            return TemplatesManager.deletePackageFromBrochure(this, brochureContainer, proposal, packageModel);
        },

        deleteServiceImageOnBrochure: function deleteServiceImageOnBrochure(brochureContainer, proposal, serviceModel, packageModel) {
            return TemplatesManager.deleteServiceImageOnBrochure(this, brochureContainer, proposal, serviceModel, packageModel);
        },

        deleteImageFromALaCarteService: function deleteImageFromALaCarteService(brochureContainer, serviceModel) {
            return TemplatesManager.deleteImageFromALaCarteService(this, brochureContainer, serviceModel);
        },

        updateServiceOnBrochure: function updateServiceOnBrochure(brochureContainer, proposal, serviceModel, packageModel) {
            return TemplatesManager.updateServiceOnBrochure(this, brochureContainer, proposal, serviceModel, packageModel);
        },

        addServiceToBrochureALaCarte: function addServiceToBrochureALaCarte(brochureContainer, serviceData) {
            return TemplatesManager.addServiceToBrochureALaCarte(this, brochureContainer, serviceData);
        },

        deleteServiceFromBrochureALaCarte: function deleteServiceFromBrochureALaCarte(brochureContainer, serviceModel) {
            return TemplatesManager.deleteServiceFromBrochureALaCarte(this, brochureContainer, serviceModel);
        },

        updateServiceOnBrochureALaCarte: function updateServiceOnBrochureALaCarte(brochureContainer, serviceModel) {
            return TemplatesManager.updateServiceOnBrochureALaCarte(this, brochureContainer, serviceModel);
        },

        updatePackageOnBrochure: function updatePackageOnBrochure(brochureContainer, proposal, packageModel) {
            return TemplatesManager.updatePackageOnBrochure(this, brochureContainer, proposal, packageModel);
        },

        updateBrochureProposalImage: function updateBrochureProposalImage(brochureContainer, brochureProposal, image) {
            return TemplatesManager.updateBrochureProposalImage(this, brochureContainer, brochureProposal, image);
        },

        updateServiceImageOnBrochure: function updateServiceImageOnBrochure(brochureContainer, brochureProposal, serviceModel, packageModel, image) {
            return TemplatesManager.updateServiceImageOnBrochure(this, brochureContainer, brochureProposal, serviceModel, packageModel, image);
        },

        updateImageInALaCarteService: function updateImageInALaCarteService(brochureContainer, serviceModel, image) {
            return TemplatesManager.updateImageInALaCarteService(this, brochureContainer, serviceModel, image);
        },

        getCompanyContactForms: function getCompanyContactForms() {
            if(!this.contact_forms) {
                return [];
            }

            return this.contact_forms.filter(function (contact_form) {
                return !contact_form.contact_form_flow_type || contact_form.contact_form_flow_type === 'default';
            });
        },

        getCompanyGiftCards: function getCompanyGiftCards() {
            if(!this.contact_forms) {
                return [];
            }

            return this.contact_forms.filter(function (contact_form) {
                return contact_form.contact_form_flow_type === 'gift_card' || contact_form.contact_form_flow_type === 'credits';
            });
        },

        getFlows: function getFlows() {
            return this.flows || [];
        },

        getCompanyPackages: function getCompanyPackages() {
            return this.vendor_packages;
        },

        getCompanyAgreements: function getCompanyAgreements() {
            return this.agreements;
        },

        getCompanyBrochures: function getCompanyBrochures(showOld) {
            var result = [];

            if (showOld) {
                var templates = this.brochure_containers;
                if (templates) {
                    templates.forEach(function (item) {
                        result.push(item);
                    });
                }
            }

            var newTemplates = this.brochure_templates;
            if (newTemplates) {
                newTemplates.forEach(function (item) {
                    if (item._id) {
                        result.push(item);
                    }
                });
            }

            return result;
        },

        getCompanyEmails: function getCompanyEmails() {
            return this.vendor_emails;
        },

        getCompanyQuestionnaires: function getCompanyQuestionnaires() {
            return this.questionnaires;
        },

        isSMSEnabled: function isSMSEnabled() {
            return this.sms_enabled;
        },
        isSMSPaymentRemindersEnabled: function isSMSPaymentRemindersEnabled() {
            return this.sms_payment_reminders_enabled;
        },

        getCompanyInvoices: function getCompanyInvoices() {
            return this.invoices;
        },

        getCompanyProposals: function getCompanyProposals() {
            return this.proposals;
        },

        getCompanyTimelines: function getCompanyTimelines() {
            return this.timeline_containers;
        },

        getAutomationStatus: function getAutomationStatus(automationItemId) {

            var automationInfo = this.getAutomationInfo(automationItemId);

            if (!automationInfo) {
                automationInfo = {};
                automationInfo.automation_type_cd = automationItemId;
                automationInfo.is_automation_on = false;

                // if we decide to return all object, need to set default template
            }

            return automationInfo.is_automation_on;
        },

        getAutomationTimeFrame: function getAutomationTimeFrame(automationType) {

            var automationInfo = this.getAutomationInfo(automationType);

            if (automationInfo && automationInfo.relevant_time_frame) {
                return automationInfo.relevant_time_frame;
            }

            return null;
        },

        getAutomationFlow: function getAutomationFlow(automationType) {

            var automationInfo = this.getAutomationInfo(automationType);

            if (this.flows && this.flows.length) {
                if (automationInfo && automationInfo.flow_template_id) {
                    return this.flows.find(function (flow) {
                        return flow._id === automationInfo.flow_template_id;
                    }.bind(this));
                }

                var filteredFlows = this.flows.filter(function (flow) {
                    return flow.action_block_types.length === 0;
                });
                if (filteredFlows.length) {
                   return filteredFlows[0];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },

        getAutomationEmailTemplate: function getAutomationEmailTemplate(automationType, templateType) {
            var templateArray = this.getCompanyEmails();

            var automationInfo = this.getAutomationInfo(automationType);

            if (automationInfo) {
                // find the template in the company templates - by id
                var templateById = _.find(templateArray, function (obj) {
                    return obj._id === automationInfo.email_template_id;
                });

                if (templateById) {
                    return templateById;
                }
            }

            // return the template by the type, or just the first one
            var template = _.find(templateArray, function (obj) {
                return obj.email_type === templateType;
            });

            if (!template) {
                template = templateArray[0];
            }

            return template;
        },

        getAutomationSelectedQuestionnaireTemplate: function getAutomationSelectedQuestionnaireTemplate(automationType) {
            var templateArray = this.getCompanyQuestionnaires();

            var automationInfo = this.getAutomationInfo(automationType);

            if (automationInfo) {
                // find the template in the company templates - by id
                var templateById = _.find(templateArray, function (obj) {
                    return obj._id === automationInfo.questionnaire_template_id;
                });

                if (templateById) {
                    return templateById;
                }
            }

            return null;
        },

        getAutomationQuestionnaireTemplate: function getAutomationQuestionnaireTemplate(automationType) {

            var template = this.getAutomationSelectedQuestionnaireTemplate(automationType);

            if (template) {
                return template;
            }

            // return the first questionnaire
            return this.getCompanyQuestionnaires()[0];
        },

        getAutomationSelectedBrochureTemplate: function getAutomationSelectedBrochureTemplate(automationType) {

            var automationInfo = this.getAutomationInfo(automationType);
            if (automationInfo) {

                // find the template in the company templates - by id
                var templateArray = this.getCompanyBrochures();
                var templateById = _.find(templateArray, function (obj) {
                    return obj._id === automationInfo.brochure_container_template_id;
                });

                if (templateById) {
                    return templateById;
                }
            }

            return null;
        },

        getAutomationBrochureTemplate: function getAutomationBrochureTemplate(automationType) {

            var template = this.getAutomationSelectedBrochureTemplate(automationType);

            if (template) {
                return template;
            }

            // return the first questionnaire
            return this.getCompanyBrochures()[0];
        },

        getAutomationEventType: function getAutomationThankYouEventType(automationType) {
            var automationInfo = this.getAutomationInfo(automationType);
            return automationInfo ? automationInfo.event_type : null;
        },

        getAutomationInfo: function getAutomationInfo(automationType) {
            if (!this.workflow_automation_infos) {
                return undefined;
            }

            return this.workflow_automation_infos.find(function (obj) {
                return obj.automation_type_cd === automationType;
            });
        },

        isShowDateConflictsAutomationOn: function isShowDateConflictsAutomationOn() {
            return this.getAutomationStatus(Enums.WorkflowAutomationTypes.show_date_conflicts);
        },

        setAutomationTimeFrame: function setAutomationTimeFrame(automationItemId, timeFrame) {
            return CompaniesManager.setAutomationTimeFrame(this, automationItemId, timeFrame);
        },

        setAutomationFlow: function setAutomationFlow(automationItemId, flow) {
            return CompaniesManager.setAutomationFlow(this, automationItemId, flow);
        },

        setAutomationEventType: function setAutomationTimeFrame(automationItemId, eventType) {
            return CompaniesManager.setAutomationEventType(this, automationItemId, eventType);
        },

        updateAutomationCustomEmail: function updateAutomationCustomEmail(actionType, subject, body) {
            return CompaniesManager.updateAutomationCustomEmail(this, actionType, subject, body);
        },

        setAutomationStatus: function setAutomationStatus(automationItemId, isOn, emailTemplateId, questionnaireTemplateId, brochureTemplateId,flowTemplateId) {
            return CompaniesManager.setAutomationStatus(this, automationItemId, isOn, emailTemplateId, questionnaireTemplateId, brochureTemplateId, flowTemplateId);
        },

        setBookingRating: function setBookingRating() {
            return CompaniesManager.setBookingRating(this);
        },

        setAutomationEmailTemplateId: function setAutomationEmailTemplateId(automationItemId, templateId) {
            return CompaniesManager.setAutomationEmailTemplateId(this, automationItemId, templateId);
        },

        setAutomationQuestionnaireTemplateId: function setAutomationQuestionnaireTemplateId(automationItemId, templateId) {
            return CompaniesManager.setAutomationQuestionnaireTemplateId(this, automationItemId, templateId);
        },

        setAutomationBrochureTemplateId: function setAutomationBrochureTemplateId(automationItemId, templateId) {
            return CompaniesManager.setAutomationBrochureTemplateId(this, automationItemId, templateId);
        },

        clearAutomationAttachment: function clearAutomationAttachment(automationItemId) {
            return CompaniesManager.clearAutomationAttachment(this, automationItemId);
        },

        doesCurrUserHaveTeamMembers: function doesCurrUserHaveTeamMembers() {
            var currUser = UsersManager.getCurrUser();
            var has_other_members = this.team_members && this.team_members.length > 0;
            return has_other_members || (this.owner && currUser && this.owner._id !== currUser._id);
        },

        removeTeamMember:function removeTeamMember(memberId) {
            this.team_members =  this.team_members.filter(function (member) {
                return member._id !== memberId;
            });
        },

        getAllCompanyMembers: function getAllCompanyMembers() {
            var ret = [];
            if (!!this.team_members) {
                ret = this.team_members.slice(0);
            }
            if (!!this.owner) {
                ret.unshift(this.owner);
            }
            return ret;
        },

        getOtherCompanyMembers: function getOtherCompanyMembers(teamMemberId) {
            var currUser = UsersManager.getCurrUser();
            return this.getAllCompanyMembers().filter(function (member) {
                return teamMemberId ? member._id !== teamMemberId : member._id !== currUser._id;
            });
        },

        isCompanyTeamMember: function isCompanyTeamMember(user_id) {
            if (!user_id) {
                return false;
            }

            return this.getAllCompanyMembers().some(function(member){
               return member._id === user_id;
            });
        },

        getTeamMemberById: function getTeamMemberById(userId) {
            if (this.owner && this.owner._id === userId) {
                return this.owner;
            }
            else if (this.team_members) {
                return this.team_members.find(function(user) {
                    return user._id === userId;
                });
            }
            return null;
        },

        setNotificationStatus: function setNotificationStatus(notificationTypeId, isOn) {
            return CompaniesManager.setNotificationStatus(this, notificationTypeId, isOn);
        },

        /** Contact Form Actions */
        updateContactForm: function updateContactForm(contactForm) {
            return TemplatesManager.updateContactForm(this, contactForm);
        },

        contactFormCreateQuestion: function contactFormCreateQuestion(question, contactForm) {
            return TemplatesManager.createContactFormQuestion(this, contactForm, question);
        },

        contactFormCreateEventQuestions: function contactFormCreateEventQuestions(contactForm) {
            // dvir: in use?
            return TemplatesManager.createEventQuestions(this);
        },

        contactFormUpdateQuestion: function contactFormUpdateQuestion(question, contactForm) {
            return TemplatesManager.updateContactFormQuestion(this, contactForm, question);
        },

        contactFormUpdateQuestionsOrder: function contactFormUpdateQuestionOrder(contactForm) {
            return TemplatesManager.updateContactFormQuestionsOrder(this, contactForm);
        },

        contactFormDeleteQuestion: function contactFormDeleteQuestion(question, contactForm) {
            return TemplatesManager.deleteContactFormQuestion(this, contactForm, question);
        },

        contactFormUpdateStyle: function contactFormUpdateStyle(contactForm){
            return TemplatesManager.updateStyle(this, contactForm);
        },

        setUserAsInquiryRecipient: function setUserAsInquiryRecipient(user_id, contactForm, isMigrate) {
            return TemplatesManager.setUserAsInquiryRecipient(this, contactForm, user_id, isMigrate);
        },

        setContactFormCaptchaType: function setContactFormCaptchaType(type, contactForm) {
            return TemplatesManager.setContactFormCaptchaType(this, contactForm, type);
        },

        setContactFormRedirectUrl: function setContactFormRedirectUrl(redirectUrl, contactForm) {
            return TemplatesManager.setContactFormRedirectUrl(this, contactForm, redirectUrl);
        },

        publishContactForm: function publishContactForm(contactForm) {
            return TemplatesManager.publishContactForm(this, contactForm);
        },

        getContactFormPreview: function getContactFormPreview(contactForm) {
            return TemplatesManager.getContactFormPreview(this, contactForm);
        },

        getDefaultCoverImage: function getDefaultCoverImage() {
            if (this.default_cover_image && this.default_cover_image._id) {
                return this.default_cover_image;
            }

            if (!this.default_cover_image_id) {
                return null;
            }

            if (!this.cover_images || !_.isArray(this.cover_images)) {
                return null;
            }

            var cover_image = _.findWhere(this.cover_images, {_id: this.default_cover_image_id });
            return cover_image;
        },

        isVenue: function isVenue() {
            return this.company_type === "venue";
        },

        isPhotographer: function isPhotagrapher() {
            return this.company_type === "photographer";
        },

        hasTagline: function hasTagline() {
            return ['business_consultant', 'coaching'].includes(this.company_type);
        },

        setDefaultCoverImage: function setDefaultCoverImage(cover_image_id) {
            return CompaniesManager.setDefaultCoverImage(this, cover_image_id);
        },

        isConnectedToQuickBooks: function isConnectedToQuickBooks() {
            return this.quickbooks_integration && this.quickbooks_integration.connected_at;
        },

        isContactFormActive: function isContactFormActive() {
            var publishedContactForms = [];
            if (this.contact_forms) {
                publishedContactForms = this.contact_forms.filter(function (contact_form) {
                    return !!contact_form.last_publish_timestamp;
                });
            }
            return this.widget_requests > 1 || publishedContactForms.length > 0;
        },

        shouldShowMembership: function shouldShowMembership() {
            return this.show_membership_page !== false;
        },

        getDefaultAgreementId: function getDefaultAgreementId() {
            return this.default_agreement;
        },

        useCompanyNameForStatementDescr: function useCompanyNameForStatementDescr() {
            return this.company_name && this.company_name.replace(/[\*"'<>]/g, '').length > 4;
        },

        calculateStripeStatementDescr: function calculateStripeStatementDescr() {
            if (this.useCompanyNameForStatementDescr()) {
                return this.company_name.replace(/[\*"'<>]/g, '').slice(0, 21);
            } else if (this.owner && this.owner.full_name) {
                return this.owner.full_name.replace(/[\*"'<>]/g, '').slice(0, 21);
            } else {
                return 'N/A';
            }
        },

        getCompanyIconImage: function() {
            var transformation = {transformation: [{
                flags: 'lossy',
                crop: 'lfill',
                width: 48,
                height: 48,
                quality: 'auto:best',
                fetch_format: 'auto',
                dpr: "auto"
            }]};

            // first, take image url
            var imageUrl = this.icon_image ? this.icon_image.url : "";

            // if cloudinary id exists, take that
            if(this.icon_image && this.icon_image.cloudinary_public_id) {
                imageUrl = ImageService.getCloudinaryUrl(this.icon_image.cloudinary_public_id, transformation);
            }

            return imageUrl;
        },

        getCompanyIconPublicId: function() {

            var imagePublicId = "";
            if (this.icon_image && this.icon_image.cloudinary_public_id) {
                imagePublicId = this.icon_image.cloudinary_public_id;
            }

            return imagePublicId;
        },

        // ==============================
        // Dynamic content actions
        // ==============================

        updateTemplate: function updateTemplate(template) {
            return TemplatesManager.updateTemplate(this, template);
        },

        /*****************************************
         * SECTION ACTIONS
         ******************************************/

        addSection: function addSection(template, fileDynamicContent) {
            return TemplatesManager.createTemplateSection(this, template);
        },

        updateSection: function updateSection(template, fileDynamicContent, section) {
            return TemplatesManager.updateTemplateSection(this, template, section);
        },

        deleteSection: function deleteSection(template, fileDynamicContent, section) {
            return TemplatesManager.deleteTemplateSection(this, template, section);
        },

        updateSectionsOrder: function updateSectionsOrder(template, fileDynamicContent, sectionsOrder) {
            return TemplatesManager.updateTemplateSectionsOrder(this, template, sectionsOrder);
        },

        /*****************************************
         * BLOCKS ACTIONS
         ******************************************/

        addBlock: function addBlock(template, fileDynamicContent, section, params) {
            return TemplatesManager.createTemplateBlock(this, template, section, params);
        },

        updateBlock: function updateBlock(template, fileDynamicContent, section, block, changedFields) {
            return TemplatesManager.updateTemplateBlock(this, template, section, block, changedFields);
        },

        deleteBlock: function deleteBlock(template, fileDynamicContent, section, block) {
            return TemplatesManager.deleteTemplateBlock(this, template, section, block);
        },

        updateSectionBlocksOrder: function updateSectionBlocksOrder(template, fileDynamicContent, section, blocksOrder) {
            return TemplatesManager.updateTemplateSectionBlocksOrder(this, template, section, blocksOrder);
        },

        /*****************************************
         * COMPONENTS ACTIONS
         ******************************************/

        updateComponent: function updateComponent(template, fileDynamicContent, section, block, component, path, changedFields, params) {
            return TemplatesManager.updateTemplateBlockComponent(this, template, section, block, component, path, changedFields, params);
        },

        /*****************************************
         * NEW PROPOSAL ACTIONS
         ******************************************/

        updateProposalOnBlock: function updateProposalOnBlock(template, fileDynamicContent, section, block, proposalData) {
            return TemplatesManager.updateProposalOnBlock(this, template, section, block, proposalData);
        },

        addPackageToBlock: function addPackageToBlock(template, fileDynamicContent, section, block, packageId) {
            return TemplatesManager.addPackageToBlock(this, template, section, block, packageId);
        },

        updatePackageOnBlock: function updatePackageOnBlock(template, fileDynamicContent, section, block, packageModel) {
            return TemplatesManager.updatePackageOnBlock(this, template, section, block, packageModel);
        },

        deletePackageFromBlock: function deletePackageFromBlock(template, fileDynamicContent, section, block, packageModel) {
            return TemplatesManager.deletePackageFromBlock(this, template, section, block, packageModel);
        },

        updatePackageServicesOrderOnBlock: function updatePackageServicesOrderOnBlock(template, fileDynamicContent, section, block, packageModel, order) {
            return TemplatesManager.updatePackageServicesOrderOnBlock(this, template, section, block, packageModel, order);
        },

        addServiceToBlock: function addServiceToBlock(template, fileDynamicContent, section, block, packageModel) {
            return TemplatesManager.addServiceToBlock(this, template, section, block, packageModel);
        },

        updateServiceOnBlock: function updateServiceOnBlock(template, fileDynamicContent, section, block, service, packageModel) {
            return TemplatesManager.updateServiceOnBlock(this, template, section, block, service, packageModel);
        },

        updateServiceImageOnBlock: function updateServiceImageOnBlock(template, fileDynamicContent, section, block, service, image, packageModel) {
            return TemplatesManager.updateServiceImageOnBlock(this, template, section, block, service, image, packageModel);
        },

        deleteServiceFromBlock: function deleteServiceFromBlock(template, fileDynamicContent, section, block, service, packageModel) {
            return TemplatesManager.deleteServiceFromBlock(this, template, section, block, service, packageModel);
        },

        updateProposalOrder: function updateProposalOrder(template, fileDynamicContent, section, block, order) {
            return TemplatesManager.updateProposalOrder(this, template, section, block, order);
        },

        moveServiceOnBlock: function moveServiceOnBlock(template, fileDynamicContent, section, block, serviceId, sourcePackageId, destPackageId, order) {
            return TemplatesManager.moveServiceOnBlock(this, template, section, block, serviceId, sourcePackageId, destPackageId, order);
        },

        shouldShowPricingSuggestion: function shouldShowPricingSuggestion (){
            return this.isPhotographer();
        },

        isMainCompany: function isMainCompany(user) {
            return this._id === user.default_company_id;
        },

        hasClientPortal: function hasClientPortal() {
            return this.company_url_prefixes && this.company_url_prefixes.length;
        },

        getClientPortalHost: function getClientPortalHost() {

            // return empty string if not defined
            if(!this.company_url_domain ||
                !this.company_url_prefixes ||
                !this.company_url_prefixes.length) {
                return "";
            }

            var domain = this.company_url_domain;
            var prefix = this.company_url_prefixes[this.company_url_prefixes.length - 1];
            return domain + "." + prefix;
        },

        shouldHideProjectField: function shouldHideProjectField(fieldName) {
            return this.hidden_project_fields && this.hidden_project_fields.indexOf(fieldName) !== -1;
        },

        shouldShowProjectField: function shouldShowProjectField(fieldName) {
            return !this.shouldHideProjectField(fieldName);
        },

        haveActiveCustomDomain: function haveActiveCustomDomain() {
            var custom_domain = CompanyService.getActiveCustomDomain(this.company_custom_domains);
            return custom_domain ? custom_domain.full_hostname : null;
        },

        canUploadMaterials: function() {
            return this.offer_account_setup;
        },

        canUploadMultipleTimes: function() {
            return this.account.account_setup_stage === 'not_ready' || !!this.last_date_to_upload
        },

        _hasMaterialsUploadStatus: function(status) {
            return this.canUploadMultipleTimes()
                   ? this.upload_files_batches && this.upload_files_batches.includes(status)
                   : this.account.account_setup_stage === status;
        },

        hasPendingMaterialsUpload: function() {
            return this._hasMaterialsUploadStatus('ready_to_start')
        },
        
        hasCompletedMaterialsUpload: function() {
            return this._hasMaterialsUploadStatus('completed')
        },

        hasUploadedMaterials: function() {
            return this.hasPendingMaterialsUpload() || this.hasCompletedMaterialsUpload();
        },

        hasOneMaterialsUploadLeft: function() {
            var MAX_UPLOAD_COUNT = 3;
            return this.canUploadMaterials() &&
                   this.upload_files_batches && 
                   this.upload_files_batches.length === MAX_UPLOAD_COUNT - 1;
        },
        
        getEnabledTeamMembers: function() {
            return this.team_members && this.team_members.filter(function(member) {
                return !member.is_disabled;
            });
        },

        isConsultant: function () {
            return this.company_vertical === 'consultant';
        }
    });
    
    return CompanyModel;
};
