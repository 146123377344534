(function () {
    "use strict";

    // @ngInject
    function LoginControllerCtor($scope, $injector, $stateParams, $timeout, $state, $location, AppStates, $uibModalStack, $localStorage,
                                 InitialAppLoadParamsService, AppConfigService, DatadogRUMService, UsersManager, AnalyticsService, RedirectService, ToastService, RegexService,
                                 UiPersistenceService, _, ClientPortalService, $window, RecaptchaService, ModalService, $translate,
                                 Constants, StatsigService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'LoginController';
        AnalyticsService.trackPageView(this, 'login', this.getPageViewAnalyticsArgs($stateParams, $localStorage));

        this.UsersManager = UsersManager;
        this.AnalyticsService = AnalyticsService;
        this.RedirectService = RedirectService;
        this.ToastService = ToastService;
        this.UiPersistenceService = UiPersistenceService;
        this.RecaptchaService = RecaptchaService;
        this.DatadogRUMService = DatadogRUMService;
        this.AppConfigService = AppConfigService;
        this._ = _;
        this.$stateParams = $stateParams;
        this.$localStorage = $localStorage;
        this.ClientPortalService = ClientPortalService;
        this.InitialAppLoadParamsService = InitialAppLoadParamsService;
        this.RegexService = RegexService;
        this.ModalService = ModalService;
        this.$location = $location;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$translate = $translate;
        this.Constants = Constants;

        this.showForgotPassword = false;
        this.showResetPasswordSent = false;
        this.emailNotRecognized = false;
        this.showTwoFactorAuth = false;
        this.showSetupTwoFactorAuth = false;
        this.trustThisDevice = true;
        this.showMessageBanner = false;
        this.messageBannerTitle = "";
        this.messageBannerText = "";
        this.RecaptchaService.load();
        this.isVendorLoginLoaded = false;
        this.isReactVendorLogin = false;

        StatsigService.isGateEnabled('ng2react-login-ui')
            .then(function(result) {
                this.isReactVendorLogin = result;
                if (!this.isReactVendorLogin) {
                    //note: 'wcb', 'lca' are for a test of redirection from marketing homepage BOAZ
                    InitialAppLoadParamsService.cleanParamsFromView(['extend_trial', 'discount', 'code', 'wcb', 'lca', 'referral_source']);
                }
            }.bind(this))
            .finally(function() {
                this.isVendorLoginLoaded = true;
            }.bind(this));

        console.debug("isInClientPortal");
        if (this._isInClientPortal()) {
            console.debug("True - isInClientPortal");
            this.showClientPortal = true;
        } else {
            this.user = UsersManager.newUser();
            this.loginParams = {
                loginSuccess: this.loginSuccess.bind(this),
                loginError: this.loginError.bind(this),
                user: this.user
            };

            if (this.$stateParams.vendor && this.ClientPortalService.isInClientPortal()) {
                this.portalModel = this.ClientPortalService.getConfiguration();
            }

            this.preFillUserEmail();

            this.error = this.getError();
            this.showSSOLogin = $state.current.name && $state.current.name.indexOf(AppStates.root_core_clientLogin) === -1;
            this.showExtendTrial = !!(InitialAppLoadParamsService.getParam('extend_trial'));
            this.showDiscountText = !!(InitialAppLoadParamsService.getParam('discount'));
            this.showReferralText = !!(InitialAppLoadParamsService.getParam('code'));
        }

        // Close any open modal so it won't be shown after login
        $uibModalStack.dismissAll();
        $window.performance && $window.performance.mark('hb_login_shown');

        this.handlePasteOrPrefill();
        this.on2faSetupComplete = this.on2faSetupComplete.bind(this);
        
        const shouldImportJBTemplates =
            InitialAppLoadParamsService.getParam('utm_source') === 'johnbranch';
        if (shouldImportJBTemplates) {
            localStorage.setItem(this.Constants.storage.IMPORT_JB_TEMPLATES, 1);
        }
        
        const shouldImportFUTemplate =
            InitialAppLoadParamsService.getParam('utm_source') === 'freelancersunion';
        if (shouldImportFUTemplate) {
            localStorage.setItem(this.Constants.storage.IMPORT_FU_TEMPLATE, true);
        } 
    }

    Controllers.LoginController = Class(Controllers.BaseController, {

        constructor: LoginControllerCtor,

        clearErrors: function clearErrors() {
            this.loginForm.attempted = false;
            //this.user.lastError = null; I marked this off as it hides the error message on input focus.
        },

        // This weird code is due to: https://honeybook.atlassian.net/browse/HON-13801
        getError: function getError() {
           var errorParam = this.$stateParams.error || this.InitialAppLoadParamsService.getParam('error');

            if (errorParam === 'EMAIL_EXISTS') {
                return 'Email already exists in HoneyBook. Try to log in or click on \'Forgot?\' to reset your password.'
            }

            if (errorParam && errorParam !== 'EMAIL_EXISTS') {
                return 'Login failed.'
            }

            return undefined;
        },

        resetTheForgotForm: function resetTheForgotForm() {
            this.showForgotPassword = false;
            this.showResetPasswordSent = false;
            this.emailNotRecognized = false;
        },

        loginHelper: function loginHelper(recaptchaToken) {
            this.UsersManager.login(
                this.user,
                'link',
                { linkId: this.$stateParams.link_id },
                this.trustThisDevice,
                recaptchaToken
            ).then(
                this.loginSuccess.bind(this),
                this.loginError.bind(this)
            );
        },

        login: function login(e) {
            this.loginForm.attempted = true;

            if (this.loginForm.$invalid) {
                if (e) {
                    e.preventDefault();
                }
                return;
            }
            this.loggingIn = true;

            if (this.AppConfigService.isTest() || this.AppConfigService.isE2E()) {
                this.loginHelper(null);
            } else {
                this.RecaptchaService.getRecaptchaToken("login").then(function(recaptchaToken) {
                    this.loginHelper(recaptchaToken);
                }.bind(this)).catch(function (err) {
                    this.DatadogRUMService.addError(err, {});
                    this.loginHelper(null);
                });
            }
        },

        loginSuccess: function loginSuccess(res, skip_2fa) {
            this.loggingIn = false;

            if (!this.user.isTempPassLogin() && !skip_2fa) {
                // If user needs to have 2FA enabled, redirect to the 2FA set up page
                if (this.user.isPendingSetupTwoFactorAuth()) {
                    this.showSetupTwoFactorAuth = true;
                    return;
                } else if (this.user.isTwoFactorAuthLoginEnabled()) {
                    this.showTwoFactorAuth = true;
                    return;
                }
            }

            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.pipeline_view, null);
            this.RedirectService.redirect(this.user);
        },

        loginError: function loginError(resp) {
            this.loggingIn = false;
            this.error = this._.get(resp, ['data', 'error_message']) || this.$translate.instant("LOGIN.LABELS.ERROR._GENERIC_");
            if (resp.data && resp.data.error) {
                var error_type = resp.data.error_type;
                if (error_type === "HBClientUserLoginFailedError") {
                    this.showLoginError("LOGIN.CLIENT_LOGIN_ERROR._TITLE_", "LOGIN.CLIENT_LOGIN_ERROR._BODY_");
                } else if (error_type === "HBPasswordExpiredError") {
                    this.ModalService.openExpiredPasswordModal(this.user.email, this.loginParams);
                }
            }
            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.login, resp);
        },

        showLoginError: function (title, body) {
            this.showMessageBanner = true;
            this.messageBannerTitle = title;
            this.messageBannerText = body;
        },

        twoFactorValidationSuccess: function twoFactorValidationSuccess(resp) {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.pipeline_view, null);
            if (this.user.isBookkeeper() && this.user.shouldStartTrial()) {
                this.AnalyticsService.trackAction(this, this.AnalyticsService.analytics_events.toggle_bookkeeper_mode, {
                    source: 'pretrialer_login',
                    mode: true
                });

                // todo - what if ATWS AND Bookkeeper
                this.UsersManager.toggleBookkeeperMode(true).then(function () {
                    this.RedirectService.redirect(this.user);
                }.bind(this));
            } else {
                this.RedirectService.redirect(this.user);
            }
        },

        twoFactorValidationError: function twoFactorValidationError(error) {
            this.loggingIn = false;
            if (error.data.error_type !== "HBTwoFactorAuthError") {
                this.ToastService.showError({
                    iconCSSClass: 'icon-hb-nx-exclamation-mark-circle',
                    content: 'Something went wrong, please login again.',
                    dismissOnTimeout: true,
                    timeout: 5000,
                    dismissButton: true
                });
                this.showTwoFactorAuth = false;
                this.UsersManager.preLogin(this.user);
            }
        },

        on2faSetupComplete: function on2faSetupComplete() {
            this.showTwoFactorAuth = true;
            this.showSetupTwoFactorAuth = false;
        },

        openForgotPassword: function openForgotPassword() {
            this.RecaptchaService.load(); // pre-load the recaptcha service... no need to handle the response
            this.showForgotPassword = true;
            this.user.lastError = '';
            this.user.password = '';
            this.loginForm.$setPristine();
        },

        sendResetPassword: function sendResetPassword(form) {

            if (!form.$valid) {
                return;
            }

            this.sendingResetPassword = true;
            var args = {
                email: this.user.email
            };

            var params = {};
            this.UsersManager.fillExtendTrialData(params);
            this.RecaptchaService.getRecaptchaToken("resetPassword").then(function(recaptchaToken) {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.forgot_password_request, args);

                params["g-recaptcha-response-v3"] = recaptchaToken;
                this.UsersManager.sendResetPassword(this.user, params).then(
                    this.resetPasswordSuccess.bind(this),
                    this.resetPasswordError.bind(this)
                );
            }.bind(this)).catch(this.resetPasswordError.bind(this));
        },

        resetPasswordSuccess: function resetPasswordSuccess() {
            this.showForgotPassword = false;
            this.showResetPasswordSent = true;
            this.sendingResetPassword = false;

            var args = {
                email: this.user.email
            };
            this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.forgot_password_email, args);
        },

        resetPasswordError: function resetPasswordError(resp) {
            this.sendingResetPassword = false;
            this.emailNotRecognized = true;

            var args = {
                email: this.user.email
            };
            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.forgot_password_email, resp, args);
        },

        preFillUserEmail: function preFillUserEmail() {
            var emailAddress = this.$stateParams.email || (this.$localStorage.hb_user_details && this.$localStorage.hb_user_details.email);
            if (emailAddress && this.RegexService.isEmailValid(emailAddress)) {
                this.user.email = emailAddress;
            }
        },

        getPageViewAnalyticsArgs: function getPageViewAnalyticsArgs($stateParams, $localStorage) {
            var args = {};
            if ($stateParams.email) {
                //if we got an email from the query string place on the input field
                args = {
                    pre_filled_email: $stateParams.email,
                    source: 'url param'
                };
            } else if($localStorage.hb_user_details && $localStorage.hb_user_details.email) {
                args = {
                    pre_filled_email: $localStorage.hb_user_details.email,
                    pre_filled_name: $localStorage.hb_user_details.full_name,
                    source: 'local storage'
                };
            }

            return args;
        },

        _isInClientPortal: function _isInClientPortal() {
            return this.ClientPortalService.isInClientPortal() && !this.$stateParams.vendor;
        },

        onCreateNewAccount: function onCreateNewAccount() {
            this.UsersManager.getCurrUser().isLocatedInUSOrCanada().then(function(isLocationInUsOrCanada) {
                if (isLocationInUsOrCanada) {
                    this.$window.location.assign(this.$window.location.origin + '/signup');
                }
                else {
                    this.ModalService.openNonUsaWarningModal('login_page');
                }
            }.bind(this));
        },

        handlePasteOrPrefill: function handlePasteOrPrefill() {
        this.$timeout(function() {
            var emailInput = document.querySelector('#login-form-email-input');
            if (!emailInput) {
                return;
            }
            emailInput.addEventListener('input', function(e) {
                if (e.inputType === 'insertFromPaste') {
                    var emailValue = e.target.value;
                    this.user.email = emailValue;
                    this.loginForm.email.$modelValue = emailValue;
                    this.loginForm.email.$setViewValue(emailValue);
                    this.loginForm.email.$setValidity('required', true);
                    this.loginForm.email.$setTouched();
                    this.loginForm.email.$setDirty();
                }
            }.bind(this));
        }.bind(this), 0)
        },

        onMessageBannerDismiss: function onMessageBannerDismiss() {
            this.showMessageBanner = false;
        },
    });
}());
