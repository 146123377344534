/**
 * Created by inonstelman on 1/4/15.
 */
(function () {
    'use strict';

    // @ngInject
    function AnalyticsServiceCtor(AppConfigService, RepositoryService, DeviceService, Mixpanel, $log, $window,
                                  Gon, FeaturesService, $timeout, DatadogRUMService, FullStoryService) {
        this.AppConfigService = AppConfigService;
        this.Mixpanel = Mixpanel;
        this.$log = $log;
        this.$window = $window;
        this.$timeout = $timeout;
        this.RepositoryService = RepositoryService;
        this.DeviceService = DeviceService;
        this.Gon = Gon;
        this.FeaturesService = FeaturesService;
        this.DatadogRUMService = DatadogRUMService;
        this.FullStoryService = FullStoryService;

        this.mobileOsParams = {};
        DeviceService.getMobileOs(this.mobileOsParams);
        this.mobileDevice = DeviceService.getMobileDevice();
        this.initialReferrer = document.referrer;
        this.initialReferrerDomain = this.extractDomain(this.initialReferrer);
        this.isSegmentAvailable = this.Gon.enable_segment &&
            this.$window.analytics &&
            angular.isFunction(this.$window.analytics.identify) &&
            angular.isFunction(this.$window.analytics.track);
        this.reportMixpanelViaSegment = this.isSegmentAvailable && this.Gon.block_mixpanel;
        this.segmentDefaultIntegrations = {
            All: true,
            Iterable: false
        };
        this.allowedFullStoryEventNames = ['action', 'click', 'blur', 'success', 'load', 'loaded', 'redirect', 'error', 'confirm', 'cancel', 'scroll', 'preview', 'rename', 'delete'];

        // Super Properties are properties that are sent to Mixpanel on every event;
        // Originally applied with the `mixpanel.register` function
        this.superProperties = {};
    }

    Services.AnalyticsService = Class(function () {

        return {
            analytics_events: {
                connecting_social_network: 'connecting social network',
                disconnecting_social_network: 'disconnecting social network',
                button_click: 'button clicked: ',
                link_clicked: 'link clicked: ',
                hide_invite_modal_active_project: 'hide invite modal active project',
                load: 'load',
                action: 'action',
                success: 'success',
                error: 'error',
                login: 'login',
                login_page: 'page view: login',
                admin_login_as: 'admin logged in as...',
                admin_login: 'admin log-in',
                login_with_facebook: 'login with facebook',
                login_with_google: 'login with google',
                login_with_apple: 'login with apple',
                login_with_passkeys: 'login with passkeys',
                client_login_as: 'client logged in as',
                client_onsite_logout: 'client onsite logout',
                signup: 'sign-up',
                host_updated_to_event: 'host updated to event',
                host_removed_from_event: 'host removed from event',
                user_added_to_workspace: 'user added to workspace',
                reset_password: 'reset password',
                reset_password_success: 'reset password success',
                reset_password_error: 'error: while resetting password',
                reset_password_request: 'reset password request',
                network_reset_password: 'network reset password',
                network_reset_password_success: 'network reset password success',
                forgot_password_email: 'send reset password email',
                forgot_password_request: 'forgot password request',
                open_reset_password: "user opened reset password",
                get_user_for_reset_password_success: "got user for reset password (valid user id & guid)",
                get_user_for_reset_password_error: "error: getting user for reset password",
                get_user_for_create_password_success: "got user for create password (valid user id & guid for new account)",
                get_user_for_network_reset_password_error: "error: getting user for network reset password",
                get_user_for_network_create_password_success: "success: got user for network create password (valid user id & guid for new account)",
                terms_of_service_viewed: 'terms of service viewed',
                terms_of_service_modal_viewed: 'viewed terms of service modal',
                terms_of_service_toast_viewed: 'viewed terms of service toast',
                terms_of_service_accepted: 'accepted terms of service',
                invite_form_event_page_submitted: 'invite from marketing event page submitted',
                invite_request_form_submitted: 'request invite form submitted',
                credit_card_added: 'credit card added',
                event_cover_photo_changed: 'event cover photo changed',
                workspace_file_cover_photo_changed: 'workspace file cover photo changed',
                company_cover_photo_changed: 'company cover photo changed',
                contract_signature_description_changed: 'contract signature description changed',
                contract_signed: 'contract signed',
                contract_seen: 'contract seen',
                proposal_seen: 'proposal seen by client',
                payment_paid: 'payment submitted',
                company_updated: 'company updated',
                contract_selected: 'contract selected',
                contract_line_edited: 'contract line edited',
                contract_saved: 'contract saved',
                agreement_open_input_field_popup: 'agreement input field popup open',
                agreement_add_input_field: 'agreement add input field',
                agreement_change_input_field: 'agreement change input field',
                agreement_add_payments_region: 'agreement add payments region',
                agreement_add_packages_region: 'agreement add packages region',
                agreement_add_header_region: 'agreement add header region',
                agreement_client_signature_locked_fields: 'client cannot sign agreement with empty fields',
                agreement_vendor_signature_locked_fields: 'vendor cannot sign agreement with empty fields',
                agreement_floating_bubble_scroll_to_next_required_field: 'agreement floating bubble scroll to next required field',
                agreement_floating_bubble_scroll_to_next_required_field_vendor: 'agreement floating bubble scroll to next required field vendor',
                agreement_floating_bubble_scroll_to_signature: 'agreement floating bubble scroll to signature',
                agreement_floating_bubble_scroll_to_signature_vendor: 'agreement floating bubble scroll to signature vendor',
                agreement_vendor_send_locked_fields: 'vendor cannot send agreement with empty fields',
                agreement_client_signature_locked_accept_changes: 'client cannot sign agreement without accepting changes',
                agreement_failed_to_save_file: 'failed to save agreement file',
                file_sent: 'file sent',
                first_file_sent: 'first file sent',
                file_deleted: 'file deleted',
                file_unarchived: 'file unarchived',
                file_unarchived_error: 'error: while unarchiving file',
                workspace_deleted: 'workspace deleted',
                workspace_deleted_error: 'error: while deleting workspace',
                workspace_canceled: 'workspace canceled',
                lead_mail_sent: 'lead mail sent',
                show_proposal_mail_preview: 'show proposal mail preview',
                event_updated: 'event updated',
                event_created: 'event created',
                payments_container_selected: 'payments container selected',
                payment_added: 'payment added',
                payment_updated: 'payment updated',
                payment_date_type_updated: 'payment date type updated',
                payment_date_updated: 'payment date updated',
                payment_amount_updated: 'payment amount updated',
                payment_update_error: 'error: payment updated',
                payment_deleted: 'payment deleted',
                payment_reminder_sent: 'payment reminder sent',
                payment_reminder_error: 'error: payment reminder',
                payment_marked_as_paid: 'payment marked as paid',
                payment_mark_as_paid_error: 'error: payment marked as paid',
                package_added_to_file: 'package added to file',
                package_deleted_from_file: 'package deleted from workspace file',
                package_deleted_from_file_error: 'error: delete package',
                service_deleted_from_package: 'service deleted from package',
                service_deleted_from_a_la_carte: 'service deleted from a la carte proposal',
                service_added_to_package: 'service added to package',
                service_added_to_package_error: 'error: adding service to package',
                service_drag_in_package_started: 'drag service in a package start',
                service_drag_in_a_la_carte_started: 'drag service in a la carte proposal start',
                service_drag_in_a_la_carte_ended: 'drag service in a la carte proposal end',
                service_drag_in_a_la_carte_error: 'drag service in a la carte proposal ',
                service_drag_in_package_ended: 'drag service in a package end',
                service_drag_in_package_error: 'drag service in a package',
                service_deleted_from_proposal: 'service deleted from proposal',
                service_added_to_proposal: 'service added to proposal',
                service_added_to_proposal_error: 'error: adding service to proposal',
                proposal_saved: 'proposal saved',
                credit_card_deleted: 'credit card deleted',
                proposal_description_updated: 'proposal description updated',
                proposal_discount_updated: 'proposal discount updated',
                proposal_tax_updated: 'proposal tax updated',
                proposal_tax_type_changed: 'proposal tax type changed',
                proposal_svc_updated: 'proposal svc updated',
                proposal_svc_type_changed: 'proposal svc type changed',
                proposal_remove_all_tax: 'proposal remove tax from all',
                proposal_remove_all_tax_error: 'error: proposal removing tax from all',
                proposal_add_tax_to_all: 'proposal add tax to all',
                proposal_add_tax_to_all_error: 'error: proposal add tax to all',
                proposal_drag_package_started: 'drag package in proposal start',
                proposal_drag_package_ended: 'drag package in proposal end',
                proposal_drag_package_error: 'error: drag package in proposal',
                create_workspace_file: 'create workspace file',
                file_title_updated_from_modal: 'file title updated from modal',
                file_title_updated_from_header: 'file title updated from header',
                file_print_successful: 'file print view was opened successfully',
                file_print_error: 'error: opening file print view',
                new_version_available: 'new version available',
                uploading_single_file: 'uploading single file',
                getting_aws_policy: 'getting aws policy',
                error_uploading_single_file: 'error: in upload process - single file',
                error_uploading_single_file_to_s3: 'error: in upload process to amazon - single file',
                user_uploaded_photos_successful: 'photos uploaded successfully',
                scrolled: 'scrolled',
                dashboard_loaded: 'dashboard loaded',
                honeybook_loaded: 'honeybook loaded',
                account_created: 'account created',
                workspace_filter_by_emails: 'filter workspace by emails',
                workspace_filter_by_files: 'filter workspace by files',
                workspace_filter_by_payments: 'filter workspace by payments',
                workspace_name_updated_successfully: 'workspace name update',
                user_updated_workspace_name: 'workspace name changed',
                workspace_name_updated_error: 'error: in workspace name update',
                download_monthly_report: 'download monthly report',
                save_contact: 'contact saved',
                save_contact_unsuccessful: 'error: while saving a contact',
                delete_contact: 'contact deleted',
                delete_contact_unsuccessful: 'error: while deleting a contact',
                update_contact_tags: 'contact tag update',
                update_contact_tags_unsuccessful: 'error: while updating contact\'s tags',
                invite_contact: 'contact invited',
                invite_contact_unsuccessful: 'error: while inviting contact',
                connect_to_google: 'connect to google popup opened',
                connect_to_google_successful: 'google for contacts connected',
                connect_to_google_unsuccessful: 'error: connect to google for contacts',
                connect_to_google_canceled: 'connection to google for contacts canceled',
                connect_to_google_option_show: 'connection to google for contacts show',
                contacts_page: 'contacts',
                user_changed_personal_info: 'personal info changed',
                bank_account_updated: 'bank account updated',
                bank_account_update_error: 'error: bank account update ',
                bank_account_deleted: 'bank account deleted',
                bank_account_delete_error: 'error: bank account delete',
                plaid_account_setup_error: 'error: plaid account setup failure',
                close_connect_bank_account: 'close connect bank account modal',
                connect_bank_account_learn_more: 'connect bank account learn more',
                confirm_connect_bank_account: 'confirm add account(s)',
                open_plaid_terms_of_service: 'open plaid terms of service',
                open_hb_privacy_policy: 'open hb privacy policy',
                open_refund_help_center: 'open refund help center',
                use_different_account: 'use different account',
                confirm_bank_sub_account: 'confirm chosen plaid bank sub account(s)',
                toggle_sub_account: 'toggle plaid sub account selection',
                connect_another_account: 'connect another plaid bank account',
                done_connecting_account: 'done connecting plaid bank account',
                stripe_token_created: 'stripe token created',
                stripe_person_token_created: 'stripe person token created',
                new_workspace_created: 'workspace created',
                new_workspace_created_error: 'error: workspace created',
                add_new_credit_card: 'credit card added',
                add_new_credit_card_and_pay: 'credit card added and pay',
                toggle_paying_outside_us: 'toggle paying outside the us',
                toggle_paying_international: 'toggle paying international',
                add_new_credit_card_error: 'error: credit card added',
                add_new_credit_card_and_pay_error: 'error: credit card added',
                delete_credit_card: 'credit card deleted',
                add_bank_account: 'add bank account',
                access_payments_when_fully_paid: 'access payments section when the file is fully paid, redirect to workspace',
                delete_template_permanently_confirmed: 'delete template permanently',
                delete_template_permanently_failed: 'delete template permanently failed',
                template_load: 'load template',
                template_selected_confirmed: 'confirm template selected',
                brochure_template_selected_confirmed: 'brochure attachment selected',
                automation_event_type_changed: 'event type changed',
                brochure_template_selected_error: 'brochure attachment selection failed',
                questionnaire_template_selected_confirmed: 'questionnaire attachment selected',
                questionnaire_template_selected_error: 'questionnaire attachment selection failed',
                questionnaire_question_drag: 'drag question',
                questionnaire_optional_answer_drag: 'drag optional answer',
                questionnaire_before_submit_warning: 'loaded: questionnaire before submit warning',
                questionnaire_submit_warning_confirmed: 'click: confirm questionnaire warning',
                questionnaire_submit_warning_dismissed: 'click: dismissed questionnaire warning',
                no_template_selected_confirmed: 'no attachment selected',
                template_selected_failed: 'template selection failed',
                template_save_confirmed: 'template save',
                template_save_failed: 'template save failed',
                template_save_as_confirmed: 'template save as',
                template_save_as_failed: 'template save as failed',
                template_import_pdf_clicked: 'template import pdf clicked',
                template_import_pdf_updated: 'template import pdf updated',
                add_user_dialog_added_user_by_email: 'added user by email',
                add_user_dialog_found_user_by_email: 'found user by email',
                add_user_dialog_vendor_account_created: 'vendor account created',
                add_user_dialog_client_account_created: 'client account created',
                add_user_dialog_save_user_success: 'user saved successfully',
                add_user_dialog_save_user_error: 'user save failed',
                team_member_account_created: 'vendor account created',
                member_added_to_workspace: 'member added to workspace',
                member_added_to_event: 'member added to event',
                vendor_added_for_referral: 'vendor added for referral',
                assigned_workspace_to_team_member: 'assign workspace to team member',
                assigned_workspace_to_team_member_error: 'error when assigning workspace to team member',
                team_member_added_to_workspace: 'team member added to workspace',
                add_team_member_to_workspace_error: 'error when adding team member to workspace',
                updated_workspace_team_members: 'update workspace team members',
                updated_workspace_team_members_error: 'error when updating workspace team members',
                sending_file_on_behalf_of_owner_open_email: 'team member wants to send email on behalf of owner',
                sending_file_on_behalf_of_owner_confirmed: 'team member confirmed send email on behalf of owner',
                sending_file_on_behalf_of_owner_canceled: 'team member canceled send email on behalf of owner',
                confirm_assign_popup: 'confirm assign popup',
                event_page_details_edit: 'edit event details',
                open_create_file: 'open_create_file',
                top_nav_2023: 'top_nav_2023',
                event_details_edit_button: 'click: edit event details',
                event_details_save_button: 'click: save event details',
                search_in_dashboard: 'search in dashboard',
                search_in_left_panel_events: 'search in left panel events',
                search_result_item_clicked: 'click: searched item',
                choose_template_success: 'template chosen',
                choose_template_error: 'error: choose template',
                save_as_template_success: 'template save as',
                save_as_template_error: 'error: save template',
                update_template_success: 'template updated',
                update_template_error: 'error: update template',
                set_default_template_success: 'default template updated',
                set_default_template_error: 'error: update default template',
                workspace_pipeline_tab_selected: 'click: pipeline tab',
                workspace_pipeline_move_to_stage: 'pipeline: dropdown moving to stage ',
                workspace_update_pipeline_settings: 'pipeline: update settings',
                workspace_event_details_tab_selected: 'click: event details Tab',
                workspace_block: 'archived workspace',
                workspace_unblock: 'unarchived workspace',
                vendor_referred_a_workspace: 'Vendor sent a referal to another vendor',
                admin_moved_a_workspace: 'Admin request to move workspace to another vendor',
                widget_color_changed: 'Widget Color Changed',
                company_setup_files_dropped: 'Company Setup: Vendor dropped files on the company setup uploader hot-spot',
                company_setup_files_selected: 'Company Setup: Vendor selected files on the company setup uploader hot-spot',
                vendor_uploaded_setup_file_successful: 'Company Setup: Vendor upload a file to s3 successfully',
                vendor_setup_file_successful_saved_on_company_model: 'Company Setup: after s3 upload files saved in company model',
                vendor_deleted_file_while_uploading: 'Complany Setup: Vendor canceled a file upload while uploading',
                error_unable_to_add_setup_file_to_company_settings:'Company Setup: error in adding an s3 setup file to the company settings',
                cover_image_successfully_uploaded: 'upload an image successfully',
                uploaded_file_saved_on_company_model: 'uploaded file saved in company model',
                cover_image_saved_on_company_model: 'uploaded image saved in company model',
                cover_image_deleted_while_uploading: 'canceled a file upload while uploading',
                set_company_default_image: 'set company default image',
                error_unable_to_add_image_to_company_settings: 'error in adding an s3 gallery image file to the company settings',
                send_workspace_feed_message: 'send workspace feed message',
                undo_workspace_feed_message: 'undo workspace feed message',
                send_event_details: 'send event details',
                files_dragged_and_dropped: 'files were dragged',
                files_selected: 'files were selected',
                pipeline: 'pipeline view',
                open_refer_workspace_from_url: 'workspace opened from referral link',
                open_assign_workspace_from_url: 'workspace opened from assign link',
                avatar_popup_hover_event: 'avatar popup hover',
                avatar_hover_event: 'avatar hover',
                create_brochure_colleciton: 'create brochure collection',
                delete_brochure_colleciton: 'delete brochure collection',
                send_brochure_collection_selection: 'send brochure collection selection',
                create_timeline_section: 'create timeline section',
                delete_timeline_section: 'delete timeline section',
                timeline_item_drag_started: 'drag timeline item start',
                timeline_item_drag_ended: 'drag timeline item end',
                timeline_item_drag_error: 'drag timeline item error',
                create_workspace_from_url: 'create workspace from link',
                widget_service_changed: 'widget service changed',
                widget_members_changed: 'widget members changed',
                update_feed_item_comment: 'update feed item comment',
                delete_feed_item_comment: 'delete feed item comment',
                onboarding_company_icon_and_logo_updated: 'onboarding company icon and logo updated',
                onboarding_updated_cover_image: 'onboarding updated cover image',
                promo_codes_submitted: 'promo code submitted',
                image_changed_on_carousel: 'image changed on carousel',
                dismiss_preferred_vendors_modal: 'click: dismiss preferred vendors modal',
                attach_preferred_vendors_list: 'click: attach preferred vendors list',
                uploaded_pvl_file: 'uploaded pvl file',
                contact_form_published_modal: 'contact form publish modal',
                update_file_auto_expire: 'updated file auto expire',
                no_singnature_before_sending_validaiton: 'no signature before sending validation',
                add_signature_from_validation: 'add signature from validation',
                update_users_default_file_auto_expire: 'update users default updated file auto expire',
                file_expired: 'file_expired',
                file_unexpired: 'file_unexpired',
                pipeline_stage_drag_started: 'started to drag a pipeline stage',
                pipeline_stage_drag_dropped: 'dropped a pipeline stage',
                client_first_name_field_button: 'add client first name field',
                add_email_signature_field_button: 'add email signature name field',
                remove_email_signature_field_button: 'remove email signature name field',
                uploaded_file_deleted: 'uploaded file deleted',
                go_to_event_from_lead_modal: 'click: dismiss go to event from lead modal',
                dismiss_go_to_event_from_lead_modal: 'click: dismiss go to event from lead modal',
                refer_a_friend_button_show: 'show: refer a friend button',
                refer_a_friend_button_click: 'click: refer a friend button',
                my_benefits_button_click: 'click: my benefits button',
                see_more_ways_to_share: 'click: see more ways to share',
                my_benefits_referrals_tab_button_click: 'my benefits - referrals',
                referrals_refer_tab: 'refer tab',
                referral_dashboard_tab: 'referral dashboard tab',
                referral_dashboard: 'referral dashboard',
                referrals_track_tab: 'referrals tracking tab',
                referrals_resources_tab: 'referrals resources tab',
                referrals_suggestion_add: 'referral suggestion added',
                referral_suggestions: 'referral suggestions',
                gmail_sync: 'gmail sync',
                my_benefits_partners_tab_button_click: 'my benefits - partners',
                my_benefits_partners: 'referrals discount tab',
                my_benefits_partners_coupon_click: 'show coupon',
                new_inquiry_notification_click: 'click: new inquiry notification',
                new_hot_inquiry_cnr_notification_click: 'click: new hot lead notification conversion and revenue',
                new_hot_inquiry_auto_suggestion_notification_click: 'click: new hot lead notification with auto suggestion',
                new_hot_inquiry_cnv_notification_click: 'click: new hot lead notification conversion',
                new_hot_inquiry_rev_notification_click: 'click: new hot lead notification revenue',
                payment_received_notification_click: 'click: payment received notification',
                agreement_signed_notification_click: 'click: agreement signed notification',
                update_contact_notes: 'click: update contact notes',
                send_conversation_message: 'click: send conversation message',
                network_update_user_profile: 'click: save network user profile',
                network_edit_user_profile: 'network edit profile',
                network_filter_state: 'click: network directory filter state',
                network_filter_city: 'click: network directory filter city',
                network_edit_user_profile_state: 'click: network edit profile state',
                network_edit_user_profile_company_type: 'click: network edit profile company type',
                network_clear_search_text: 'click: network search clear text',
                network_save_referral: 'click: save network referral',
                open_menu_on_calendar: 'calendar menu open',
                open_popover_on_calendar: 'calendar popover open',
                view_payment_on_calendar_popover: 'view payment calendar popover',
                view_project_on_calendar_popover: 'view project calendar popover',
                create_meeting_on_calendar_popover: 'create meeting calendar popover',
                delete_meeting_on_calendar_popover: 'delete meeting calendar popover',
                edit_meeting_on_calendar_popover: 'edit meeting calendar popover',
                open_calendar_secondary_popover: 'open calendar secondary popover',
                create_project_on_calendar_popover: 'create project calendar popover',
                calendar_toolbar_change_view: 'calendar toolbar change view',
                network_save_project: 'click: save network project',
                network_create_project: 'network create project',
                network_project_publish: 'network project published',
                network_edit_project: 'network edit project',
                network_edit_project_type: 'click: network edit project type',
                network_vendor_tagged_in_project: 'vendor tagged in project',
                network_onboarding_introduction_load: 'network onboarding introduction load',
                network_onboarding_introduction_submit: 'network onboarding introduction submit',
                network_onboarding_introduction_close: 'network onboarding introduction close',
                network_unlock_hb_load: 'network unlock hb load',
                network_unlock_hb_cancel: 'network unlock hb cancel',
                network_unlock_hb_confirm: 'network unlock hb confirm',
                network_improve_profile_confirm: 'network improve profile confirm',
                network_improve_profile_cancel: 'network improve profile cancel',
                network_deleted_project: 'project deleted',
                network_deleted_project_error: 'error: delete project',
                network_cancel_deleted_project: 'project cancel deleted',
                network_cancel_deleted_project_error: 'error: delete project',
                network_user_invited: 'network user invited',
                network_user_accept: 'click: network user accept',
                network_user_connection_request: 'network user connection request',
                network_projects_location: 'click: network projects location',
                network_dismiss_feed_intro: 'network feed intro dismiss',
                network_feed_load_more: 'network feed load more',
                network_unsubscribe_from_email: 'network unsubscribe from email',
                unsubscribe_from_email_loaded: 'unsubscribe from email loaded',
                network_edit_referral: 'network edit referral',
                network_moderate_referral: 'network edit referral',
                network_notification_settings_update_radius: 'network update notification radius',
                image_saved_on_network_project_model: 'upload network project image',
                reset_projects_search: 'reset projects search',
                close_projects_drawer: 'close projects drawer',
                show_more_upcoming_events: 'click: see more upcoming events',
                filmstrip_item_drag_started: 'filmstrip drag item started',
                filmstrip_item_drag_finished: 'filmstrip drag item finished',
                filmstrip_item_drag_error: 'filmstrip drag item error',
                ae_calendly_link: 'ae calendly link',
                calendar_date_picked: 'calendar date picked',
                open_facebook_leads_modal: 'open facebook leads modal',
                facebook_page_connected: 'facebook page connected' ,
                facebook_page_disconnected: 'facebook page disconnected' ,
                go_to_chrome_extension: 'go to chrome extension',
                send_file: 'send file',
                user_birth_updated: 'user day of birth updated',
                identification_image_selected: 'user selected identification image not saved yet',
                identification_image_empty_modal_response: 'identification image modal empty response',
                identification_image_saved: 'user selected identification image and saved on server',
                identification_image_missing_data_url: 'identification image modal missing data_url',
                identification_image_upload_error: 'identification image upload to server error',
                generating_thumb_for_selected_image: 'generating thumb for selected image',
                files_selected_dropped_to_ngf: 'files_selected_dropped_to_ngf',
                no_files_for_thumb_generation: 'no files found while generating thumbs',
                too_many_items: 'too many items',
                in_product_intake: 'success: in product intake',
                in_product_intake_form_submit: 'click: in product intake form submit',
                example_proposal_promo_close_keep_exploring: 'example proposal promo closed, keep exploring',
                example_proposal_promo_close_no_thanks: 'example proposal promo closed, move to homepage',
                onboarding_guidance_icon_and_logo_updated: 'onboarding guidance icon and logo updated',
                onboarding_guidance_uploading_file_header_from_mobile: 'onboarding guidance uploading file header from mobile',
                onboarding_guidance_opening_crop_dialog: 'onboarding guidance opening crop dialog',
                onboarding_guidance_opening_crop_dialog_for_editing: 'onboarding guidance opening crop dialog for editing',
                onboarding_guidance_saved_file_header: 'onboarding guidance saved file header',
                onboarding_guidance_profile_image_updated: 'onboarding guidance profile image updated',
                click_onboarding_add_brands_next:"click: onboarding add brands next",
                click_onboarding_add_service_next:"click: onboarding add service next",
                company_updated_timezone: 'company updated timezone',
                spaces_clicked_on_goto_cal: 'user clicked on gotoCalendar from event spaces',
                spaces_clicked_on_done: 'closed spaces modal',
                spaces_clicked_on_add_space: 'click: add space',
                spaces_added_space: 'space added',
                spaces_space_name_updated: 'space name updated',
                spaces_clicked_on_delete_space: 'click: delete space',
                spaces_clicked_on_delete_space_confirmed: 'click: delete space confirmed',
                company_spaces__modal_opened: 'company_spaces__modal_opened',
                company_spaces__saved: 'company_spaces__saved',
                company_spaces__modal_closed: 'company_spaces__modal_closed',
                company_spaces__item_add_clicked:'company_spaces__item_add_clicked',
                company_spaces__item_edit_clicked: 'company_spaces__item_edit_clicked',
                company_spaces__item_edit_confirmed: 'company_spaces__item_edit_confirmed',
                company_spaces__item_delete_clicked: 'company_spaces__item_delete_clicked',
                company_spaces__item_delete_confirmed: 'company_spaces__item_delete_confirmed',
                company_spaces__item_delete_canceled: 'company_spaces__item_delete_canceled',
                trialer_left_file: 'trialer left file',
                schedule_walkthrough: 'schedule a walk through',
                onboarding_webinar:'onboarding webinar',
                get_referrals_stats: 'get referral stats',
                add_email_signature_logo: 'add email signature logo',
                save_gmail_integration_auth_code: 'save gmail integration auth code',
                gmail_integration_google_error: 'save gmail integration auth code',
                save_zoom_integration_auth_code: 'save zoom integration auth code',
                zoom_cancel_on_close_meeting_modal: 'cancel zoom meeting on close modal',
                zoom_integration_error: 'error save zoom integration auth code',
                quickbooks_integration_error: 'save quickbooks integration auth code',
                save_nylas_integration_auth_code: 'save nylas integration auth code',
                nylas_integration_error: 'error save nylas integration auth code',
                delete_email_integration_confirmed: 'delete email integration confirmed',
                delete_email_integration_canceled: 'delete email integration cancelled',
                email_integration_received_provider: 'received provider name',
                email_integration_failed_provider: 'failed to receive email provider',
                redirect_to_nylas_success: 'redirecting to nylas site',
                redirect_to_nylas_failure: 'failure redirecting to nylas site',
                unselect_payment_type: 'payment schedule not chosen',
                open_refund_payment_section: 'click: start refund',
                click_refund_amount_input: 'click: refund amount',
                continue_with_refund_payment: 'click: refund amount modal continue',
                continue_with_refund_payment2: 'click: accept cc missing continue',
                confirm_refund_payment: 'click: confirm refund',
                payment_refund_error: 'Error refunding payment',
                payment_refund_succcess: 'Success refunding payment',
                payment_refund_amount_changed: 'refund amount changed',
                cc_input_continue: 'click: refund credit card continue',
                cc_payment_method_missing_for_refund: 'CC payment method missing for refund',
                cc_payment_method_found_for_refund: 'loaded: refund credit card input',
                select_accepted_payment_method: 'select accepted payment method',
                select_toast_show:'select toast show',
                hotkey_used: 'hotkey used',
                remove_team_member_error: 'Error removing team member',
                remove_team_member_success: 'Success removing team member',
                confirm_team_member_removal: 'click: confirm team member removal',
                not_confirm_team_member_removal: 'click: no confirm for team member removal',
                login_as_client: 'click: login as client',
                help_center: 'help center',
                task_mgmt_add_new_task: 'task mgmt: add new task',
                task_mgmt_delete_task: 'task mgmt: delete task',
                task_mgmt_update_task: 'task mgmt: update task',
                task_mgmt_mark_complete_task: 'task mgmt: mark task as complete',
                company_expense_creates: 'new company expense was created',
                company_expense_update: 'company expense was updated',
                company_expense_delete: 'delete company expense',
                company_expense_export: 'export company expense to csv',
                company_expense_change_filter:'expense change filter',
                profit_and_loss_change_filter:'profit and loss change filter',
                workflow_action_selected: 'workflow action selected',
                workflow_created: 'workflow: created',
                workflow_deleted: 'workflow: deleted',
                workflow_duplicated: 'workflow: duplicated',
                workflow_updated: 'workflow: updated',
                workflow_update_project_type: 'workflow: update project type',
                workflow_update_public_flows: 'workflow: update public flows',
                workflow_apply_to_workspace: 'workflow: workflow apply to workspace',
                workflow_remove_from_workspace: 'workflow: workflow remove from workspace',
                workflow_open_full_view: 'workflow: open full view',
                workflow_mark_step_as_approved: 'workflow: mark step as approved',
                workflow_mark_task_as_approved: 'workflow: mark task as approved',
                workflow_mark_step_email_as_approved: 'workflow: mark step email as approved',
                workflow_mark_as_declined: 'workflow: mark as declined',
                batch_emails_send_test_email_to_myself: 'batch_emails: Send test email to myself',
                batch_emails_send_emails: 'batch_emails: batch emails sent',
                on_boarding_four_panels_load_panel: 'loaded: onboarding panel',
                on_boarding_four_panels_click_panel_next: 'click: onboarding panel next',
                on_boarding_four_panels_click_panel_jump: 'click: onboarding panel dot jump',
                on_boarding_four_panels_click_panel_create_project: 'click: onboarding panel create project',
                on_boarding_four_panels_click_panel_close: 'click: onboarding panel closed',
                on_boarding_panels_click_panel_view_agreement_templates: 'click: onboarding panel view agreement templates',
                on_boarding_quiz_load: 'loaded: onboarding quiz',
                on_boarding_quiz_continue: 'click: onboarding quiz continue',
                on_boarding_quiz_error: 'click: onboarding quiz error',
                on_boarding_quiz_select: 'click: onboarding quiz option',
                onboarding_mobile_guide_load_panel: 'loaded: onboarding panels',
                onboarding_mobile_guide_click_panel_next: 'click: onboarding panel next',
                onboarding_mobile_guide_click_panel_jump: 'click: onboarding panel dot jump',
                onboarding_mobile_guide_swipe_panel_next: 'swipe: onboarding panel next',
                onboarding_mobile_guide_swipe_panel_previous: 'swipe: onboarding panel previous',
                onboarding_mobile_guide_click_panel_create_project: 'click: onboarding panel create project',
                onboarding_mobile_guide_click_panel_start_account_setup: 'click: mobile onboarding start account setup',
                onboarding_mobile_guide_click_panel_finish_account_setup: 'click: mobile onboarding finish account setup',
                onboarding_mobile_guide_click_panel_close: 'click: onboarding panel closed',
                onboarding_setup_guid_task_completed: 'setup guide task completed',

                onboarding_mobile_quiz_load_panel: 'mobile onboarding quiz',
                onboarding_mobile_quiz_click_answer: 'mobile onboarding quiz answer',
                onboarding_mobile_quiz_skip: 'mobile onboarding quiz skip',
                onboarding_mobile_quiz_back: 'mobile onboarding quiz back',
                onboarding_mobile_quiz_done: 'mobile onboarding quiz done',

                reschedule_project_modal_open: 'reschedule project modal open',
                reschedule_send_mail: '"send" in mail modal',
                reschedule_flow_cancel: 'reschedule flow canceled',
                reschedule_flow_done: 'reschedule flow done',
                reschedule_send_new_contract: '"send" of the new contract to contacts',
                reschedule_project_choose_contract_load: 'update contract',
                reschedule_project_payments_load: 'update payments',
                reschedule_project_workflow_load: 'update workflow',
                reschedule_project_choose_contract_selection: 'choose contract:',

                intake_form_loaded: 'loaded: intake form modal',
                intake_form_loaded_company_info: 'loaded: intake form company info',
                intake_form_loaded_user_info: 'loaded: intake form user info',
                intake_form_loaded_HDYHAU: 'loaded: intake form HDYHAU',
                intake_form_loaded_email: 'loaded: intake form email',
                intake_form_loaded_user_name: 'loaded: intake form user name',
                intake_form_loaded_step: 'loaded: intake form modal step',

                intake_form_submit_company_info: 'click: submit company info',
                intake_form_submit_user_info: 'click: submit user info',
                intake_form_submit_HDYHAU: 'click: submit HDYHUA',
                intake_form_submit_email: 'click: submit email',
                intake_form_submit_user_name: 'click: submit user name',
                intake_modal_click_submit: 'click: intake form modal submit',
                intake_modal_submit_success: 'success: intake form modal submitted',
                intake_modal_redirect: 'redirect: was old intake modal, redirect to new /signup',
                intake_form_submit_success: 'success: intake form submitted',
                mta_start_a_trial: 'mta user trial start',
                facebook_sso_new_user: 'facebook first sign up',
                registration_validation_redirect: 'Registration validation redirect',
                intake_email_domain_not_valid: 'Intake form error email domain not valid',
                intake_form_submit_error: 'Intake Form Submit error',
                intake_form_phone_number_test_variant: 'ABC Test Phone Number Experiment',
                intake_form_aa_test_variant: 'AA Test Optimize Experiment',
                onboarding_templates: 'onboarding templates',
                toggle_setup_guide_template: 'toggle setup guide template',
                setup_guide: 'setup guide',
                setup_guide_completed: 'setup guide completed',
                setup_guide_next: 'setup guide next',
                setup_guide_modal_subscription: 'setup guide modal subscription',
                setup_guide_modal_task: 'setup guide modal task',
                toggle_setup_guide_modal_task: 'toggle: setup guide modal task',
                setup_guide_modal_footer_link: 'setup guide modal footer link',
                account_setup_export_csv: 'click: download invoice csv',
                notifications_center: "notifications center",
                activity_notifications_settings_toggle: "activity_notifications_settings_toggle",
                user_clicked_email_field: 'user email field clicked',
                user_cancelled_on_2fa_heads_up_popup: 'user cancelled on 2fa heads up popup',
                two_factor_auth_success: '2fa verification success',
                two_factor_auth_failure: '2fa verification failyre',
                user_email_change_success: 'success: user email changed',
                user_email_change_failure: 'failure: account email not changed',
                toggle_autopay: 'toggle auto pay',
                pay: 'pay',
                save_calendly_integration_auth_code: 'save calendly integration auth code',
                calendly_successful_connection: 'successfully connected to calendly',
                calendly_integration_error: 'error save calendly integration auth code',
                calendly_error_connection: 'error while trying to calendly',
                calendly_try_it_now: 'click: try it now button on integration',
                calendy_oauth_send: 'send user to calendly oauth',
                blocked_tools: 'blocked tools',
                workspace_attachments_uploaded: 'workspace attachments uploaded',
                company_additional_data_updated: 'company additional data changed',
                product_explanation_banner_close: 'click: product explanation banner closed',
                logo_image_deleted: 'company logo deleted',
                vendor_pvl_save: 'save vendor pvl',
                pipeline_column_sort: 'sort pipeline column',
                tax_amount_changed: 'tax amount changed',
                tax_title_changed: 'tax title changed',
                time_tracking_change_filter: 'time tracking: change filter',
                onboarding_bot_cta: 'onboarding bot action',
                onboarding_bot_recap_modal: 'onboarding bot recap modal',
                referral_link_shared: 'referral link shared',
                referral_shared_conformation_modal: 'referral shared confirmation modal',
                lead_source__modal_opened: "lead_source__modal_opened",
                lead_source__saved: 'lead_source__saved',
                lead_source__modal_closed: 'lead_source__modal_closed',
                lead_source__item_add_clicked:'lead_source__item_add_clicked',
                lead_source__item_edit_clicked: 'lead_source__item_edit_clicked',
                lead_source__item_edit_confirmed: 'lead_source__item_edit_confirmed',
                lead_source__item_delete_clicked: 'lead_source__item_delete_clicked',
                lead_source__item_delete_confirmed: 'lead_source__item_delete_confirmed',
                lead_source__item_delete_canceled: 'lead_source__item_delete_canceled',
                lead_source_toast__shown: 'lead_source_toast__shown',
                lead_source_toast__closed: 'lead_source_toast__closed',
                lead_source_click_dismiss_intro: 'click: dismiss lead source intro',
                lead_source_click_protip: "click: protip in lead source",
                lead_source_click_cta_intro: "click: see what its like lead source intro",
                project__lead_source_changed: 'project__lead_source_changed',
                go_to_workspace: 'go to workspace',
                go_to_contract: 'go to contract',
                go_to_flow_payment: 'go to flow payment',
                go_to_flow_contract: 'go to flow contract',
                open_tax_editor: 'open tax editor',
                referral_banner: 'referral banner',
                mta_in_product_start_trial: 'mta inproduct start trial',
                mta_subscribe: 'mta subscribe',
                services_auto_complete_loaded: 'services auto complete loaded',
                atws_banner_cta:'atws banner explore tools cta',
                open_payment_flyout: 'open payment flyout',
                recurring_payment_end_date_type_changed: 'recurring end date type changed',
                end_recurring_payment_confirmed: 'end recurring payment confirmed',
                end_recurring_payment_canceled: 'end recurring payment canceled',
                failed_save_recurring_template: 'save recurring as template failed',
                file_header_edit_background: 'file header edit background',
                file_header_add_icon: 'file header add icon',
                file_header_edit_icon: 'file header edit icon',
                file_header_delete_icon: 'file header delete icon',
                file_bill_to_set_client: 'file bill to set client',
                account_setup_prompt: 'account setup prompt',
                account_setup_prompt_close: 'close account setup prompt',
                account_setup_prompt_accept: 'accept account setup prompt',
                account_setup_modal: 'account setup',
                account_setup_success_export: 'export company expense to csv',
                archive_ws_report_requested: 'archived workspaces report requested',
                archive_ws_report_downloaded: 'archived workspaces report generated',
                failed_download_archive_ws_report: 'archived workspaces report generation failed',
                close_contact_form_demo_video: 'close contact form demo video',
                navigate_to_pricing : 'navigate to pricing',
                refer_a_friend: 'refer a friend',
                top_nav: 'top_nav',
                delete_section: 'delete section',
                updated_section_name: 'updated section name',
                dragged_section:'dragged section',
                added_block: 'added block',
                saved_block: 'saved block',
                saved_block_component: 'saved block component',
                add_block_image: 'add block image',
                edit_block_image: 'edit block image',
                showed_block_color_picker: 'showed block color picker',
                change_block_proposal_selection_type: 'change block proposal selection type',
                change_block_proposal_enable_quantity: 'change block proposal enable quantity',
                pricing_plan_toggle: 'pricing plan type toggle',
                dragged_block:'dragged block',
                home_page: 'home',
                bulk_email_intro_modal: 'Bulk Email Intro Modal',
                email_template_chooser: 'email template chooser',
                pipeline_tour: 'pipeline tour',
                pipeline_tour_next: 'pipeline tour next',
                website_builder_help_doc_open: 'website builder help doc open',
                add_recipient_client_portal_link: 'client portal add recipient link',
                user_click_delete_hdyhau_contact_form: 'click: lead source deletion alert in contact form',
                change_lead_source_in_workspace: 'click: lead source in workspace',
                clicked_lead_source_in_ws_tooltip: 'click: lead source in workspace tooltip',
                edited_client_profile_confirmed: 'client portal confirmed edit',
                client_portal_go_to_subscription: 'client portal go to subscription',
                edit_client_portal_settings: 'client portal edit settings',
                edited_client_portal_cancel: 'client portal cancel edit',
                send_client_portal_link: 'client portal send link',
                custom_domain_verify: 'enter subdomain',
                create_subdomain_verification: 'create subdomain',
                confirm_domain_connection: 'confirm domain connection',
                easy_connect_popup_closed: 'easy connect domain popup closed',
                confirm_easy_connect_domain_connection: 'confirm easy connect domain confirmation',
                open_custom_domain_modal: 'connect company subdomain',
                try_again_custom_domain: 'try again failed subdomain',
                loaded_contact_form_snippet_code: 'loaded: contact form snippet code',
                create_project_modal: 'create project modal',
                selected_new_contact_form_font:'selected new contact form font',
                question_type_changed: 'question type changed',
                file_test_mode: 'file test mode',
                test_file_sent_modal: 'test file sent modal',
                edit_project_type: 'edit project type',
                create_project_type: 'create project type',
                edit_project_lead_source: 'edit lead source',
                create_project_lead_source: 'create lead source',
                edit_project_start_date: 'edit project start date',
                edit_project_start_time: 'edit project start time',
                edit_project_end_date: 'edit project end date',
                edit_project_end_time: 'edit project end time',
                edit_project_location: 'edit project location',
                edit_project_availability: 'edit project availability',
                edit_date: 'change date',
                edit_project_timezone: 'edit project timezone',
                bank_account_type_changed: 'bank account type changed',
                delete_project_details_private_note_: 'delete project private note',
                birthday_modal: 'birthday modal',
                mweb_block_modal: 'mweb block modal',
                home_contact_form_awareness: 'home contact form awareness',
                contact_form_project_type_automation: 'contact form project type automation',
                feed_reply_mode_toggle: 'feed reply mode toggle',
                instant_deposits_more_info: 'go to instant deposits more info',
                open_debit_card_modal: 'open debit card modal',
                waive_late_fee_modal: 'waive late fee modal',
                open_waive_late_fee_modal: 'open waive late fee modal',
                close_waive_late_fee_modal: 'close waive late fee modal',
                late_fee_waived: 'late fee waived',
                automations_update_notification: 'automations update notification',
                allow_instant_deposit_for_payment: 'allow instant deposit for payment',
                debit_card_modal: 'debit card modal',
                instant_deposit_modal: 'instant deposit modal',
                instant_deposit_weekend_modal: 'instant deposit weekend modal',
                close_instant_deposit_weekend_modal: 'close instant deposit weekend modal',
                confirm_multiple_instant_deposits_toggle: 'confirm multiple instant deposits toggle',
                cancel_multiple_instant_deposits_toggle: 'cancel multiple instant deposits toggle',
                skip_and_dont_show_again: 'skip and don\'t show again',
                learn_more: 'learn more',
                instant_deposit_modal_error: 'instant deposit modal error',
                confirm_instant_deposit_modal_error: 'confirm instant deposit modal error',
                instant_deposit_load_payment: 'instant deposit load payment',
                confirm_instant_deposit: 'confirm instant deposit',
                confirm_instant_deposit_initiated: 'confirm instant deposit initiated',
                confirm_instant_deposit_error: 'confirm instant deposit error',
                add_debit_card: 'add debit card',
                close_debit_card_modal: 'close debit card modal',
                close_instant_deposit_modal: 'close instant deposit modal',
                email_send_error: 'email send error modal',
                schedule_meeting: 'schedule meeting',
                create_meeting_error: 'create meeting error',
                update_meeting_error: 'update meeting error',
                update_meeting: 'update meeting',
                connect_project_to_meeting: 'connect project',
                connect_project_to_meeting_modal: 'connect project modal',
                mweb_connect_project_to_meeting_modal: 'mweb connect project modal',
                select_project: 'select project',
                create_new_project: 'create new project',
                close_mweb_connect_project_modal: 'close mweb connect project modal',
                show_instant_deposit_tooltip: 'show instant deposit tooltip',
                toggle_instant_payment: 'toggle instant deposit for a payment',
                toggle_instant_payments: 'toggle instant deposit for multiple payments',
                deposit_now: 'deposit now',
                delete_debit_card: 'delete debit card',
                debit_card_delete_confirmation: 'debit card delete confirmation popup',
                select_team_member_scheduling: 'select team member scheduling',
                create_session: 'create new session',
                create_first_session: 'create first session',
                save_session: 'save session',
                delete_session: 'delete session',
                edit_session: 'edit session',
                cancel_edit_session: 'cancel edit session',
                discard_session_changes: 'discard session changes',
                add_session_reminder: 'add session reminder',
                remove_session_reminder: 'remove session reminder',
                add_buffer_before: 'add buffer before',
                add_buffer_after: 'add buffer after',
                session_field_changed: 'session field changed',
                session_next_availability: 'session next availability',
                session_next_confirmation: 'session next confirmation',
                session_accordion: 'session accordion',
                close_created_session_modal: 'close created session modal',
                copy_session_link: 'copy session link',
                scheduler_troubleshooter_open: 'scheduler troubleshooter opened',
                copy_session_config: 'copy session config',
                preview_session: 'preview session',
                year_in_review_loaded: 'year in review loaded',
                year_in_review_clicked: 'year in review clicked',
                year_in_review_closed: 'close year in review',
                google_autocomplete_click: 'google autocomplete clicked an option',
                google_autocomplete_enter: 'google autocomplete enter key pressed',
                google_autocomplete_paste: 'google autocomplete text pasted',
                google_autocomplete_select_first: 'google autocomplete first option selected',
                google_geocoding_opportunity: 'google geocode location map directive',
                open_schedule_send_modal: 'open schedule send modal',
                help_button: 'help button',
                schedule_send_custom_date: 'open scheduled custom date',
                cancel_schedule_email_editor: 'cancel schedule email editor',
                delete_scheduled_send_feed_item: 'deleted scheduled send feed item',
                remove_scheduled_send: 'remove scheduled send',
                corona_dashboard_page: 'corona dashboard',
                corona_email_template_modal: 'corona email template modal',
                corona_action: 'corona action',
                update_billing_info: 'update billing info',
                company_id_error: 'no company for client trying to get payment methods',
                save_as_template: 'save as template',
                cancel_save_as_template: 'cancel save as template',
                file_create: 'create file',
                load_template_to_file: 'load template to file',
                template_create: 'create template',
                template_delete: 'delete template',
                template_default: 'default template',
                template_select: 'go to template',
                template_duplicate: 'duplicate template',
                templates_sort: 'sort templates',
                templates_toggle_view: 'toggle templates view',
                discard_meeting_changes: 'discard meeting changes',
                voucher_see_questions: 'show gift card questions responses',
                voucher_applied: 'gift card applied',
                pipeline_bulk_action: 'pipeline bulk action',
                contacts_bulk_action: 'contacts bulk action',
                delete_contacts_confirm: 'delete contacts confirm',
                delete_contacts_cancel: 'delete contacts cancel',
                bulk_workspace_email: 'bulk workspace email',
                bulk_contacts_email: 'bulk contacts email',
                confirm_send_file_amount_zero: 'confirm send file amount zero',
                cancel_send_file_amount_zero: 'cancel send file amount zero',
                archive_project: 'Archive project modal',
                change_file_name_settings: 'change file name settings',
                delete_gift_card_confirmed: 'delete git card confirmed',
                delete_gift_card_canceled: 'delete git card canceled',
                delete_gift_card_errored: 'delete git card errored',
                scheduling_exposure_poc_popup_view: 'scheduling exposure poc popup view',
                scheduling_session_selected: 'scheduling session selected',
                importing_templates: 'importing templates to company',
                skip_account_setup: 'skip account setup',
                pricing_offer:  'loaded: pricing-offer',
                tasks_page:  'tasks',
                create_new: 'click: create new',
                feedback_modal: 'feedback modal',
                send_feedback: 'send feedback',
                booking_process_dashboard: 'booking process dashboard',
                edit_general_preferences: 'edit general preferences',
                toggle_company_notification: 'toggle company notification',
                edit_company_notification: 'edit company notification',
                toggle_payment_reminders: 'toggle payment reminders',
                edit_payment_reminders: 'edit payment reminders',
                toggle_actions: 'toggle actions',
                edit_actions: 'edit actions',
                select_name_format: 'select name format',
                toggle_thumbnail_visibility: 'toggle thumbnail client visibility',
                toggle_sms_reminders_enabled: 'toggle sms reminders enabled',
                onboarding_video: 'onboarding video',
                go_to_company_settings: 'to go company settings',
                download_attachment: 'download attachment',
                delete_attachment: 'delete attachment',
                account_setup_modal_load: 'account setup modal',
                account_setup_upload_more: 'account setup upload more',
                fetch_workspace_error: 'error: fetch workspace error',
                delete_workspace_from_pipeline: 'try delete workspace from pipeline menu',
                delete_workspace: 'try delete workspace',
                account_setup_next_step: 'account setup next step',
                account_setup_previous_step: 'account setup previous step',
                material_submitted_succesfully: 'we got your files migration wizard',
                click_fees_compare_link: 'click: fees compare link',
                click_schedule_a_call: 'click: schedule a call',
                load_fees_benefit_cancellation_page: 'Load: fees benefit cancellation page',
                pipeline_invoice_banner_create_invoice: 'create invoice',
                pipeline_invoice_banner_close: 'pipeline invoice banner closed',
                force_autopay_on_card_on_file_disabled: 'card on file disabled but didnt changed must-autopay on legacy file',
                pro_account_access: 'click on account access tab',
                route_change_success: 'route change success',
                scheduler_block_banner_in_tool: 'scheduler block banner in tool',
                scheduler_block_banner_in_tool_cta: 'scheduler block banner in tool cta',
                scheduler_block_banner_in_tool_dismiss: 'scheduler block banner in tool dismiss',
                add_team_member: 'add team member',
                edit_team_member_permission: 'edit permission',
                connect_facebook: 'facebook connect',
                zapier_show_key: 'zapier show key',
                blocked_feature: 'blocked feature',
                toggle_bookkeeper_mode: 'toggle bookkeeper mode',
                change_auditee_company: 'switch bookkeeper mode company',
                pricing_section_feature: 'feature pricing section',
                account_setup_faq: 'account setup faq',
                load_contact_view: 'load client workspace',
                coupon_code_applied: 'coupon code applied',
                send_workspace_message: 'send workspace message',
                // Finance app mix-panel events
                finance_invite_banner: 'finance invite banner',
                finance_1099_download_banner_cta: 'finance 1099 download banner cta',
                finance_1099_download_banner_dismiss: 'finance 1099 download banner dismiss',
            },

            constructor: AnalyticsServiceCtor,

            shouldTrackMixpanel(forceTrack) {
                if (!this.AppConfigService.isProduction()) {
                    return false;
                }

                if (forceTrack) {
                    return true;
                }

                if (this.user && this.user.isClient()) {
                    return (this.crc(this.user._id) % 100000) < (5 * 1000);
                }

                return true;
            },

            identify(user) {
                if (user) {
                    this.user = user;
                    var args = this.getUserTraits();
                    this._setSuperProperties(args);
                    if (this.shouldTrackMixpanel()) {
                        if (!this.reportMixpanelViaSegment) {
                            this.Mixpanel.get().identify(this.user.id());
                        }
                    }
                    else {
                        this.consoleEvent("Identify", args);
                    }
                }
            },

            getUserTraits() {
                var args = {};
                if (this.user) {
                    args = {
                        id: this.user._id,
                        $id: this.user._id,
                        email: this.user.email,
                        $email: this.user.email,
                        first_name: this.user.first_name,
                        $first_name: this.user.first_name,
                        last_name: this.user.last_name,
                        $last_name: this.user.last_name,
                        name: this.user.full_name,
                        $name: this.user.full_name,
                        user_type: this.user.system_user_type,
                        user_job_title: this.user.user_type_name,
                        user_hb_system_type: this.user.hb_user_type,
                        lifecyclestage: 'other',
                        'activated?': !!this.user.isActivated(), // Aligned with mobile, do not change without coordination
                        is_subscribed: this.user.companyHasSubscription(),
                        user_status: this.user.getUserStatus(),
                        flows_enabled: !!this.user.isFlagEnabled('enable_flow_flag'),
                        legacy_files_enabled: !this.user.isFlagEnabled('block_files_flag'),
                        trial_start_date: this.user.getTrialStartDate(),
                        initial_intent: this.user.user_intent
                    };
                    if (!!this.user.is_hb_admin){
                        args.user_is_hb_admin = !!this.user.is_hb_admin;
                    }

                    if (this.user.isVendor()) {
                        if (this.user.company) {
                            args.user_company_type = this.user.company.company_type_name;
                            args.user_company_name = this.user.company.company_name;
                        }

                        if (this.user.account) {
                            if (this.user.account.predicted_member_segment_measurement) {
                                args.predicted_member_segment = this.user.account.predicted_member_segment_measurement;
                            }
                        }

                        if (angular.isNumber(this.user.bucket_number)) {
                            args.bucket_number = this.user.bucket_number;
                        }
                    }
                }

                return args;
            },

            isMobile() {
                return !!navigator.userAgent.match(/(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i);
            },

            updateCompanyProperties(company) {
                //we only update this if we already set the user (identify)
                if (!this.user) {
                    return;
                }

                if (company && !this.company) {
                    this.company = company;
                }

                var args = this.getUserTraits();
                this._setSuperProperties(args);
            },

            unidentify() {
                this.user = null;
                this.company = null;
                if (!this.reportMixpanelViaSegment) {
                    this.Mixpanel.get().reset();
                    if (this.Mixpanel.get().cookie) {
                        this.Mixpanel.get().cookie.clear();
                    }
                }
                this.superProperties = {};
                this.anonymizeUser();
            },

            trackConfirm(callingScope, errorName, args) {
                return this.track(callingScope, 'confirm: ' + errorName, args);
            },

            trackCancel(callingScope, errorName, args) {
                return this.track(callingScope, 'cancel: ' + errorName, args);
            },

            trackLoad(callingScope, errorName, args) {
                return this.track(callingScope, 'load: ' + errorName, args);
            },

            trackSuccess(callingScope, errorName, args) {
                return this.track(callingScope, 'success: ' + errorName, args);
            },

            trackClick(callingScope, errorName, args) {
                return this.track(callingScope, 'click: ' + errorName, args);
            },

            trackAction(callingScope, errorName, args) {
                return this.track(callingScope, 'action: ' + errorName, args);
            },

            trackRedirect(callingScope, analyticName, args) {
                return this.track(callingScope, 'redirect: ' + analyticName, args);
            },

            trackError(callingScope, errorName, serverErrorResponse, args) {
                var errorMessage = "";
                serverErrorResponse = serverErrorResponse || {};
                if ( serverErrorResponse.data) {
                    errorMessage = serverErrorResponse.data.error_message;
                }
                var errorStatus = serverErrorResponse.status;

                var allArgs = angular.extend({error_message: errorMessage, error_status: errorStatus}, args);
                return this.track(callingScope, 'error: ' + errorName, allArgs);
            },

            trackPageView(callingScope, eventName, args) {
                return this.track(callingScope, 'loaded: ' + eventName, args);
            },

            track(callingScope, event_name, args, forceTrack) {
                if(!event_name){
                    this.$log.error('You Did Not Pass an event_name To AnalyticsService.Track() - You Should Always Pass It, event name');
                }

                if(!callingScope){
                    this.$log.error('You Did Not Pass A Scope To AnalyticsService.Track() - You Should Always Pass It, event name is: ' + event_name);
                }

                var hierarchyParams = this.getCallingHierarchy(callingScope);

                var fullArgs = angular.extend({
                    is_in_app_browser: this.DeviceService.isInAppBrowser(),
                    app_path: hierarchyParams.breadcrumbs,
                    hb_version: this.AppConfigService.appConfiguration().version,
                    hb_api_version: this.AppConfigService.appConfiguration().api_version,
                    screen_resolution: this.getScreenResolution()
                }, args);

                // add the user agent string so we'll know if users are trying to do things with awkward browser that we did not block
                if(navigator){
                    fullArgs.user_agent = navigator.userAgent;
                    fullArgs.onLine = navigator.onLine;
                }

                fullArgs = angular.extend({}, hierarchyParams.properties, fullArgs);

                var configAttributes = this.analyticsProperties;
                if (configAttributes) {
                    for (let key in configAttributes) {
                        if (typeof fullArgs[key] === 'undefined' || fullArgs[key] === null ){
                            fullArgs[key] = configAttributes[key];
                        }
                    }
                }

                //Add mobile OS params
                if(this.mobileOsParams.mobileOs){
                    fullArgs.mobile_os = this.mobileOsParams.mobileOs;

                    if(this.mobileOsParams.iosVersion){
                        fullArgs.ios_version = this.mobileOsParams.iosVersion;

                    }else if(this.mobileOsParams.androidVersion){
                        fullArgs.android_version = this.mobileOsParams.androidVersion;
                    }
                }

                this._removeUndefinedProperties(fullArgs);

                if (this.shouldTrackMixpanel(forceTrack)) {
                    if (!this.reportMixpanelViaSegment) {
                        this.Mixpanel.get().track(event_name.toLowerCase(), fullArgs);
                    } else {
                        const segmentEventArgs = angular.extend(
                            {},
                            this._getDeviceProperties(),
                            this.superProperties,
                            fullArgs
                        );
                        this.reportEvent(event_name.toLowerCase(), segmentEventArgs, {
                            All: false,
                            Mixpanel: true,
                            Statsig: true,
                            'Mixpanel (Actions)': true,
                        });
                    }
                    // report the same event to full story
                    const fullStoryArgs = angular.extend({}, fullArgs, {
                        action: this.allowedFullStoryEventNames.includes(event_name) ? event_name : 'action',
                        description: event_name
                    });

                    this.FullStoryService.trackEvent(event_name, fullStoryArgs);
                    
                    // Console logging for data team and PMs to see the analytics and data being sent in production
                    if (window.enableAnalyticsConsoleLogging) {
                        this.consoleEvent(event_name, fullArgs);
                    }
                } else {
                    this.consoleEvent(event_name, fullArgs);
                }
            },

            getScreenResolution() {
                try {
                    if (!this.__resolution) {
                        var width = window.screen.width * window.devicePixelRatio;
                        var height = window.screen.height * window.devicePixelRatio;
                        this.__resolution = Math.min(width, height) + "x" + Math.max(width, height);
                    }
                } catch (e) {
                    this.__resolution = "N/A";
                    this.DatadogRUMService.addError(
                        e,
                        {
                            message: 'Error getting screen resolution',
                        }
                    );
                }

                return this.__resolution;
            },

            consoleEvent(eventName, args) {
                return this.$log.debug('[ANALYTICS]', eventName, args);
            },

            alias(alias_name) {
                if (this.shouldTrackMixpanel() && !this.reportMixpanelViaSegment) {
                    return this.Mixpanel.get().alias(alias_name);
                }
            },

            getCallingHierarchy(scope){
            if(!scope){
                return '';
            }

            var currName = '';
            var currProperties = {};
            for (let key in scope) {

                if(!scope.hasOwnProperty(key) && key !== '$ctrl'){ // support component controllers
                    continue;
                }
                if(key === 'coreVm'){
                    return currName;
                }
                var keyEndsWithVm = (key.toLowerCase().indexOf('vm', key.length - 2) !== -1);
                if(keyEndsWithVm){

                    currName = key.substring(0, key.length - 2);
                    currName = currName.replace(/([a-z\d])([A-Z])/g, '$1' + ('_') + '$2').toLowerCase();
                    if(scope[key] && scope[key].analyticsName){
                        currName = scope[key].analyticsName;
                        currName = currName.replace(/([a-z\d])([A-Z])/g, '$1' + ('_') + '$2').toLowerCase();
                    }

                    if(typeof scope[key]['analyticsProperties'] === 'function'){
                        currProperties = scope[key].analyticsProperties();
                    }

                    break;
                }

                if(key === '$ctrl' && scope[key].__objectType) {
                    var controllerName = scope[key].__objectType;
                    const indexOfController = controllerName.toLowerCase().indexOf('controller');
                    currName = controllerName.substring(0, indexOfController);
                    currName = currName.replace(/([a-z\d])([A-Z])/g, '$1' + ('_') + '$2').toLowerCase();
                    if(typeof scope[key]['analyticsProperties'] === 'function'){
                        currProperties = scope[key].analyticsProperties();
                    }
                }

                //sometimes it is a controller that called this - it will not have a VM
                if(key === '__objectType'){

                    const indexOfController = scope[key].toLowerCase().indexOf('controller');
                    currName = scope[key].substring(0, indexOfController);

                    var indexOfDirective = scope[key].toLowerCase().indexOf('directive');
                    if(indexOfDirective !== -1){
                        currName = scope[key].substring(0, indexOfDirective);
                    }
                    currName = currName.replace(/([a-z\d])([A-Z])/g, '$1' + ('_') + '$2').toLowerCase();
                    if(typeof scope[key]['analyticsProperties'] === 'function'){
                        currProperties = scope[key].analyticsProperties();
                    }
                }

                if(key === 'analyticsName'){

                    if(scope.analyticsName){
                        currName = scope.analyticsName;
                        currName = currName.replace(/([a-z\d])([A-Z])/g, '$1' + ('_') + '$2').toLowerCase();
                    }
                }
            }

            var currRes = {
                breadcrumbs: currName,
                properties: currProperties

            };

            if(scope.$parent){

                var prevRes = this.getCallingHierarchy(scope.$parent);

                if(prevRes.breadcrumbs){

                    if(currName && (prevRes.breadcrumbs !== currName)){
                        currRes.breadcrumbs = prevRes.breadcrumbs + '/' + currName;

                    }else{
                        currRes.breadcrumbs = prevRes.breadcrumbs;
                    }
                }

                angular.extend(currRes.properties, prevRes.properties);


            }

            return currRes;
            },

            crc(str) {
                var crcTable = window.crcTable || (window.crcTable = this.makeCRCTable());
                var crc = 0 ^ (-1);

                for (var i = 0; i < str.length; i++) {
                    crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xFF];
                }

                return (crc ^ (-1)) >>> 0;
            },

            makeCRCTable() {
                var c;
                var crcTable = [];
                for (var n = 0; n < 256; n++) {
                    c = n;
                    for (var k = 0; k < 8; k++) {
                        c = ((c & 1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
                    }
                    crcTable[n] = c;
                }
                return crcTable;
            },

            // Segment

            identifyUser(user, integrations) {
                    const currCompany = user.company || {};
                    const currAccount = user.account || {trial: {}};

                    const reservedTraits = {
                        id: user._id,
                        avatar: user.profile_image_url,
                        company: {
                            id: currCompany._id,
                            name: currCompany.company_name,
                        },
                        createdAt: user.created_at,
                        email: user.email,
                        firstName: user.first_name,
                        lastName: user.last_name,
                        name: user.full_name,
                        phone: currCompany.phone_number,
                        website: user.website_url,
                    };
                    const customTraits = {
                        vertical: currCompany.company_type,
                        userType: user.system_user_type,
                        hbUserType: user.hb_user_type,
                        predictedMemberSegmentMeasurement: currAccount.predicted_member_segment_measurement,
                        trialStartDate: currAccount.trial && currAccount.trial.start_date,
                        isSubscribed: currAccount.is_subscribed,
                        activatedOnDate: user.activated_on,
                        smartFileOpenDate: currAccount.enable_flow_flag,
                        legacyFilesBlockDate: currAccount.block_files_flag,
                        smartFilesRolloutGroup: currAccount.otam_group,
                        feature_plan_id: currAccount.featurePlanId
                    };
                    if (currAccount.subscription && currAccount.subscription.pricing_plan) {
                        customTraits.pricing_plan_type = currAccount.subscription.pricing_plan.plan_type;
                        customTraits.pricing_plan_id = currAccount.subscription.pricing_plan._id;
                    }
                    const userTraits = Object.assign({}, customTraits, reservedTraits, this.getUserTraits());
                    const options = {
                        integrations: integrations || {
                            All: true,
                            Iterable: false
                        }
                    };
                if (this.isSegmentAvailable) {
                    return this.$window.analytics.identify(user._id, userTraits, options);
                } else {
                    this.$log.debug('[tracking: Segment] identifyUser');
                }
            },

            anonymizeUser() {
                if (this.isSegmentAvailable) {
                    this.$window.analytics.reset();
                }
            },

            reportEvent(eventName, eventProps, integrations) {
                const _eventProps = this.enrichEventProps(eventProps);

                if (this.isSegmentAvailable) {
                    const configurations = {
                        integrations: integrations || this.segmentDefaultIntegrations,
                    };
                    return this.$window.analytics.track(eventName, _eventProps, configurations);
                } else {
                    this.$log.debug('[tracking: Segment] ' + eventName + ', ' + JSON.stringify(_eventProps));
                }
            },

            reportIterableEvent(eventName, eventProps, reportingUser) {
                const user = reportingUser || {};
                const integrations = {
                    All: false,
                    Iterable: true
                };

                if (this.isSegmentAvailable) {
                    this.$window.analytics.identify(user._id, {email: user.email}, {integrations});
                    this.$timeout(() => {
                        this.reportEvent(eventName, eventProps, integrations);
                    }, 100);
                }
            },

            enrichEventProps(eventProps) {
                const _eventProps = eventProps || {};
                if (this.Gon.facebook_test_event_code) {
                    _eventProps.test_event_code = this.Gon.facebook_test_event_code;
                }

                if (this.user) {
                    if (this.user.statsig_stable_id) {
                        _eventProps.statsig_stable_id = this.user.statsig_stable_id;
                    }

                    _eventProps.is_bookkeeper_mode = this.user.isBookkeeperMode();
                    _eventProps.account_id = this.user.account_id;
                    _eventProps.statsigCustomIDs = ["account_id", this.user.account_id];
                }

                return _eventProps;
            },

            _setSuperProperties(superPropertiesObj) {
                const company = this.company || (this.user && this.user.company);

                if (company && company.owner) {
                    superPropertiesObj.user_company_owner_id = company.owner._id;
                    superPropertiesObj.user_company_owner_email = company.owner.email;
                }

                if (company && company.team_members) {
                    superPropertiesObj.user_company_team_members_count = company.team_members.length;
                }

                this._removeUndefinedProperties(superPropertiesObj);

                if (!this.reportMixpanelViaSegment) {
                    this.Mixpanel.get().people.set(superPropertiesObj);
                    this.Mixpanel.get().register(superPropertiesObj);
                }

                this.superProperties = superPropertiesObj;
            },

            _removeUndefinedProperties(obj) {
                if (!obj) {
                    return;
                }

                for (let key in obj) {
                    if (typeof obj[key] === 'undefined') {
                        delete obj[key];
                    }
                }
            },

            _getDeviceProperties() {
                try {
                    const props = {
                        '$screen_width': window.screen.width,
                        '$screen_height': window.screen.height,
                        '$device': this.mobileDevice,
                        '$initial_referrer': this.initialReferrer,
                        '$initial_referring_domain': this.initialReferrerDomain,
                        '$referring_domain': this.extractDomain(document.referrer),
                    };

                    if (this.user) {
                        props['$user_id'] = this.user._id;
                    }

                    return props;
                } catch (e) {
                    this.DatadogRUMService.addError(e, { action: '_getDeviceProperties' });
                }
            },

            extractDomain(path) {
                if (!path) {
                    return undefined;
                }

                const url = new URL(path);
                return url.hostname;
            }
        };
    });
}());
