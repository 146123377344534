
(function () {
    'use strict';
    Controllers.CreateProjectController = class CreateProjectController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $state, $modalInstance, moment, _, AppStates, config, AnalyticsService,
                    WorkspaceService, EventService, TimezoneService, OnboardingService,
                    UiPersistenceService, UsersManager, CompaniesManager, EventsManager, FlowsBetaUserTypeService, DatadogRUMService) {
            super($scope, $injector);
            this.__objectType = 'CreateProjectController';
            this._ = _;
            this.$state = $state;
            this.AnalyticsService = AnalyticsService;
            this.EventsManager = EventsManager;
            this.UserManager = UsersManager;
            this.CompaniesManager = CompaniesManager;
            this.WorkspaceService = WorkspaceService;
            this.OnboardingService = OnboardingService;
            this.EventService = EventService;
            this.TimezoneService = TimezoneService;
            this.UiPersistenceService = UiPersistenceService;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.DatadogRUMService = DatadogRUMService;
            this.appStates = AppStates;
            this.modalInstance = $modalInstance;
            this.currUser = UsersManager.getCurrUser();
            this.company = this.CompaniesManager.getCurrCompany();
            this.isCreationInProgress = false;
            this.project = this.EventsManager.newEvent();
            this.project.event_image = this.EventService.getInitialProjectImage(this.company);
            this.project.event_timezone = this.TimezoneService.getTimezoneForCompany(this.company);
            this.addedContactId = null;
            this.isPublicFlowsEnabled = FlowsBetaUserTypeService.setIsPublicFilesUser();
            this._initProTip();
            //two way binding HACK
            this.projectFormBinding = {};

            if (config) {
                this.doNotGoToNewWorkspace = config.doNotGoToNewWorkspace;
                if(config.dateParams) {
                    this._setInitialDate(config.dateParams);
                }
                this.addedContactId = config.userId;
            }

            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.create_project_modal);

            this._onboardingCreateEventToolTipStep(); //TODO: deprecate
        }

        createProject() {
            if(this.isCreationInProgress) {
                return;
            }
            this.isCreationInProgress = true;
            this.projectFormBinding.projectForm.$setSubmitted();
            let projectForm = this.projectFormBinding.projectForm;
            if (projectForm.$dirty && projectForm.$valid && !projectForm.errorMessage && this._isValidDate()) {
                this.EventsManager.createEvent(this.project, this.addedContactId).then((resp) => {
                    this.AnalyticsService.trackSuccess(this, 'project created', this._getProjectAnalyticsProps(this.project));
                    this.AnalyticsService.reportEvent('ProjectCreated');
                    var workspaceId = resp.data._id;
                    if (!this.doNotGoToNewWorkspace) {
                        this.WorkspaceService.gotoNewWorkspace(workspaceId, this.project._id, "general",
                            this.showLeadSourceToast, !!this.addedContactId, this.appStates.root_core_navigation_event_workspace_details);

                    }
                    this.modalInstance.close(resp.data);

                    this._onboardingCreateEventStep(workspaceId); //TODO: deprecate

                    // Add the newly created event to the master collection and manually trigger a success
                    // this is mostly used by the calendar service.
                    const eventsMasterCollection = this.EventsManager.getEventsMasterCollection();
                    eventsMasterCollection.add(this.project);

                    this.isCreationInProgress = false;
                }).catch((resp) => {
                    this.AnalyticsService.trackError(this, 'create project', this);
                    this.errorMessage = resp.data.error_message || resp.data;
                    this.isCreationInProgress = false;

                });
            }
            else {
                this.isCreationInProgress = false;
            }
        }

        cancelCreateEvent() {
            this.modalInstance.dismiss();
        }

        removeProTip(leadSource) {
            if (leadSource !== 'Unknown') {
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showLeadSourceProTip, {show: false});
                this.showLeadSourceProTip = false;
            }
        }

        _setInitialDate(date) {
            if (date.date) {
                this.project.event_date =  date.date;
            }

            if (date.time) {
                this.project.event_time_start =  date.time;
            }

            if (date.time_end) {
                this.project.event_time_end =  date.time_end;
            }
        }

        _isValidDate() {
            let startDate = this.project.startDate;
            let endDate = this.project.endDate;
            let startTime = this.project.startTime;
            let endTime = this.project.endTime;
            if(startTime && endTime) {
                startDate = this._formatTime(startDate, startTime);
                endDate = this._formatTime(endDate, endTime);
                return this.moment(startDate).isBefore(endDate);
            }
            else if(startDate && endDate) {
                return this.moment(startDate).isSameOrBefore(endDate);
            }
            return true;
        }

        _formatTime(date, time) {
            return this.moment(date + ' ' + time, 'YYYY-MM-DD HH:mm');
        }

        _initProTip() {
            // hide lead source protip for members with public files
            if(this.isPublicFlowsEnabled) {
                this.showLeadSourceProTip = false;
                return;
            }
            if (this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.showLeadSourceProTip,
                {show: true}).show && this.currUser.isVendor()) {
                this.showLeadSourceProTip = true;
            }
        }

        _getProjectAnalyticsProps(project) {
            return this._.pick(project.dataOnly(),
                'event_name', 'event_type', 'event_timezone', 'event_date', 'event_end_date',
                'event_time_start', 'event_time_end', 'lead_source');
        }

        //TODO: deprecate
        _onboardingCreateEventToolTipStep() {
            this.onboardingActive = this.OnboardingService.isOnboardingActive();

            if (this.onboardingActive) {
                this.OnboardingService.setStepComplete(this.OnboardingService.OnboardingSteps.createEventTooltip);
            }
        }

        //TODO: deprecate
        _onboardingCreateEventStep(workspaceId) {
            if (this.onboardingActive) {
                this.OnboardingService.setOnboardingWorkspace(workspaceId);
                this.OnboardingService.setOnboardingEvent(this.project._id);
                this.OnboardingService.setStepComplete(this.OnboardingService.OnboardingSteps.createEvent);
            }
        }
    };
}());